import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const CakePayment = () => {
  const [cakeName, setCakeName] = useState("");
  const [cakeOwnerName,setCakeOwnerName] = useState("");
  const [cakeId, setCakeId] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [sPrice, setSubscriptionPrice] = useState("");
  const [data, setData] = useState("");
  console.log(data);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    console.log(userData.cakeId, "Cake Id Form Local Storage");
    const cakeId = userData.cakeId;
    setData(userData);
    console.log(cakeId);
  }, []);

  function loadRazorpay() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setLoading(true);
        const result = await axios.post("/api/v1/payment/create-subs", {
          amount: sPrice * 100,
        });
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get("/api/v1/payment/get-razorpay-key");

        const options = {
          key: razorpayKey,
          amount: amount,
          currency: currency,
          name: "manasvi technologies",
          description: "transction to manasvi",
          order_id: order_id,
          handler: async function (response) {
            await axios.post("/api/v1/payment/pay-subs", {
              paymentMode: true,
              amount: amount,

              razorpay: {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
              cakeOwnerName,
              cakeId,
              cakeName,
              subscriptionPlan,
            });

            toast.success("Payment Completed Successfully ");

          },
          prefill: {
            name: "Manasvi technologies",
            email: "staff.manasvi@gmail.com",
            contact: "1111111111",
          },
          notes: {
            address: "30, minal residency bhopal D.",
          },
          theme: {
            color: "#80c0f0",
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }

  const handleCancelClick = () => {
    console.log("Form cancelled. Data cleared.");

  };



  useEffect(() => {

    if (data.subscriptionPlan === "Yearly") {
      setSubscriptionPrice("6000");
    }
    if (data.subscriptionPlan === "Half Yearly") {
      setSubscriptionPrice("3000");
    }
    if (data.subscriptionPlan === "Quarterly") {
      setSubscriptionPrice("1500");
    }
    if (data.subscriptionPlan === "Monthly") {
      setSubscriptionPrice("500");
    }

    setSubscriptionPlan(data.subscriptionPlan)
    setCakeName(data.cakeName)
    setCakeOwnerName(data.cakeOwnerName)
    setCakeId(data.cakeId)


  }, [data.subscriptionPlan]);

  return (
    <div className="container mx-auto max-w-xl p-8 bg-white ">
      <h2 className="text-2xl font-bold mb-4 text-red-600 text-center underline">
        Cake Center Payment
      </h2>
      <div className="space-y-4">
        <div>
          <label className="block font-bold text-red-500">
            Name of Cake Center:
          </label>
          <input
            type="text"
            value={data.cakeName
              }
            onChange={(e) => setCakeName(e.target.value)}
            className="border p-2 w-full text-red-400 mt-2"
            readOnly
          />
        </div>
        <div>
          <label className="block font-bold text-red-500">
            Name of Owner:
          </label>
          <input
            type="text"
            value={data.cakeOwnerName}
            onChange={(e) => setCakeOwnerName(e.target.value)}
            className="border p-2 w-full text-red-400 mt-2"
            readOnly
          />
        </div>
        <div>
          <label className="block font-bold text-red-500">
            Cake Center Id:
          </label>
          <input
            type="text"
            value={data.cakeId}
            readOnly
            className="border p-2 w-full text-red-400 mt-2"
          />
        </div>
        <div>
          <label className="block font-bold text-red-500">
            Subscription Plan:
          </label>
          <input
          type="text"
            value={data.subscriptionPlan}
            onChange={(e) => setSubscriptionPlan(e.target.value)}
            className="border p-2 w-full text-red-400 mt-2"
            
          />
        </div>

        <div>
          <label className="block font-bold text-red-500">
            Subscription Price:
          </label>
          <input
            type="text"
            value={sPrice}
            onChange={(e) => setSubscriptionPrice(e.target.value)}
            className="border p-2 w-full text-red-400 mt-2"
            readOnly
          />
        </div>
        <div>
          <button
            className="bg-green-500  text-white px-4 py-2 m-1 hover:scale-105 w-30 rounded-3xl"
            onClick={loadRazorpay}
            disabled={loading}
          >
            {loading ? "Processing ...." : "Pay Online"}
          </button>
          <button
            type="button"
            onClick={handleCancelClick}
            className="bg-red-500 text-white px-10 py-2 m-1  hover:scale-105 w-30 rounded-3xl "
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CakePayment;

import React, { useState, useEffect } from "react";
import Modal from "react-modal";


const About = ({ isOpen, onClose }) => {
 
  const [animateContent, setAnimateContent] = useState(false);
 
  useEffect(() => {
    if (isOpen) {
      // Add a delay to start the animation after the modal is open
      setTimeout(() => {
        setAnimateContent(true);
      }, 100);
    } else {
      setAnimateContent(false);
    }
  }, [isOpen]);

 

  return (
    <>
     
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="ContactUs Form"
        style={{
          content: {
            width: "60%",
            height: "60%",
            maxWidth: "600px",
            margin: "auto",
            padding: "20px",
            boxShadow: "0 4px 8px rgb(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflowY: "auto",
            transition: "transform 1s ease-out",
            transform: animateContent ? "translateY(0)" : "translateY(-80vw)", 
            border: "2px solid #fb7185"
            

          },
        }}
      >
        <div className="flex justify-end">
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div>
          <h1 className="text-center text-3xl font-bold p-2 text-rose-500">
            About Us
          </h1>
          
          <p className="text-center text-2sm mt-5">
          Welcome to Manvika Cake Services Pvt. Ltd, the online platform to order delicious cakes of your own choice.
          </p>
        </div>
        
      </Modal>
      
    </>
  );
};

export default About;

import React, { useState, useEffect, Fragment, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import Modal from "react-modal";

const ViewItems = () => {
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [name, setName] = useState("");
  const [flavour, setFlavour] = useState("");
  const [theme, setTheme] = useState("");
  const [shape, setShape] = useState("");
  const [description, setDescription] = useState("");
  const [cakeId, setCakeId] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [tier, setTier] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [cakeType, setCakeType] = useState("");
  const [photo, setPhoto] = useState("");
  const [id, setId] = useState("");
  const [unit, setUnit] = useState("");
  const [viewModal, setViewModal] = useState(false);
/*   const [viewDelModal, setViewDelModal] = useState(false);
 */
  const getAllItem = async () => {
    try {
      const { data } = await axios.get("/api/v1/item/get-item");
      if (data?.success) {
        setItem(data.item);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Something went wrong in getting food");
    } finally {
      setLoading(false);
    }
  };

  const getSingleItem = async (slug) => {
    try {
      const { data } = await axios.get(`/api/v1/item/get-singleItem/${slug}`);
      setName(data.item.name);
      setId(data.item._id);
      setFlavour(data.item.flavour);
      setTheme(data.item.theme);
      setShape(data.item.shape);
      setDescription(data.item.description);
      setCakeId(data.item.cakeId);
      setColor(data.item.color);
      setWeight(data.item.weight);
      setTier(data.item.tier);
      setPrice(data.item.price);
      setUnit(data.item.unit)
      setCategory(data.item.category);
      setCakeType(data.item.cakeType);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllItem();
  }, []);

  const handleUpdateClick = (slug) => {
    setSelectedItem(slug);
    getSingleItem(slug);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const itemData = new FormData();
            itemData.append("name", name);
      itemData.append("flavour", flavour);
      itemData.append("theme", theme);
      itemData.append("shape", shape);
      itemData.append("description", description);
      itemData.append("cakeId", cakeId);
        itemData.append("color", color);
      itemData.append("weight", weight);
      itemData.append("tier", tier);
      itemData.append("price", price);
      itemData.append("unit", unit);
            itemData.append("category", category);
      itemData.append("cakeType", cakeType);
      itemData.append("photo", photo);

      const { data } = await axios.put(
        `/api/v1/item/update-item/${id}`,
        itemData
      );
      if (data?.success) {
        toast.success("Item Updated Successfully");
        setOpen(false);
        getAllItem(); // Refresh the list of items after update
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during update");
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(`/api/v1/item/delete-item/${id}`);
      if (data?.success) {
        toast.success("Item Deleted Successfully");
        setOpen(false);
        getAllItem(); // Refresh the list of items after deletion
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during deletion");
    }
  };

  const handleDele = async (_id) => {
    const id = _id;
    console.log("Deleting item with id:", id);
    try {
      const response = await fetch(
        `http://localhost:5000/api/v1/item/delete-item/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Item successfully deleted
        toast.success("Item Deleted Successfully");
        console.log("Deleting item with id:", id);
        console.log("Item deleted successfully");
        getAllItem();
      }
      if (!id) {
        console.error("Item id is missing");
        return;
      } else {
        console.error("Error deleting item");
      }
    } catch (error) {
      toast.error("Something went wrong during deletion");
      console.error("Error deleting item:", error);
    } finally {
      // Close the modal regardless of success or failure
  /*     closeDeleteModal(); */
    }
  };
  const handleDel = async (_id) => {
    try {
      const id = _id;
      
      // Display confirmation prompt
      const confirmed = window.confirm("Are you sure you want to delete?");
      
      if (!confirmed) {
        // If user cancels deletion
        return;
      }
  
      const { data } = await axios.delete(`/api/v1/item/delete-item/${id}`);
      if (data?.success) {
        toast.success(data.message);
        console.log(data.message);
        setOpen(false);
        getAllItem();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during deletion");
    }
  };
  
  const handleViewClick = (slug) => {
    setSelectedItem(slug);
    getSingleItem(slug);
    setViewModal(true);
  };
/*   const openDeleteModal = () => {
    setViewDelModal(true);
  };
  const closeDeleteModal = () => {
    setViewDelModal(false);
  };
 */
  const openModal = () => {
    setViewModal(true);
  };

  const closeModal = () => {
    setViewModal(false);
  };

  return (
    <>
      <h1 className="text-2xl text-center uppercase font-bold mb-1 text-red-600 underline">
        All Items
      </h1>
      <div className="container mx-auto py-1 overflow-x-auto ">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="min-w-full">
            <thead>
              <tr className="text-white bg-red-500">
                <th className="py-2 px-4 text-left">Image</th>
                <th className="py-2 px-4 text-left">Shape</th>
                <th className="py-2 px-4 text-left">Cake Id</th>
                <th className="py-2 px-4 text-left">Weight</th>
                <th className="py-2 px-4 text-left">Tier</th>
                <th className="py-2 px-4 text-left">Price</th>

                <th className="py-2 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-red-300 text-black border-pink">
              {item.map((p) => (
                <tr key={p._id} className="border-b">
                  <td className="py-2 px-4">
                    <img
                      src={`/api/v1/item/get-photo/${p._id}`}
                      className="w-20 h-20"
                      alt={p.name}
                    />
                  </td>

                  <td className="py-2 px-4">{p.shape}</td>
                  <td className="py-2 px-4">{p.cakeId}</td>
                  <td className="py-2 px-4">{p.weight}{p.unit}</td>
                  <td className="py-2 px-4">{p.tier}</td>
                  <td className="py-2 px-4">{p.price}</td>

                  <td className="py-2 px-4">
                    <button
                      className="text-blue-600 hover:underline mx-2 "
                      onClick={() => handleViewClick(p.slug)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="18"
                        viewBox="0 0 576 512"
                      >
                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                      </svg>
                    </button>
                    <button
                      className="text-blue-600 hover:underline mx-2"
                      onClick={() => handleUpdateClick(p.slug)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="18"
                        viewBox="0 0 576 512"
                      >
                        +
                        <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" />
                      </svg>
                    </button>

                    <button
                      className="text-blue-600 hover:underline mx-2 "
                       onClick={() => handleDel(p._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="14"
                        viewBox="0 0 448 512"
                      >
                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
{/* 
        <Modal
          isOpen={viewDelModal}
          onRequestClose={closeDeleteModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "50%",
              height: "60%",
              maxWidth: "450px",
              margin: "auto",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflow: "hidden",
            },
          }}
        >
          <div className="flex justify-end">
            <button
              className="text-red-500 hover:text-red-400"
              onClick={closeDeleteModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h1 className="text-3xl font-semibold text-red-500 mb-4 text-center">
            Delete Item
          </h1>
          <div className="w-3/4 mx-auto">
            <div className="mb-6">
              <h3 className="text-center font-bold">
                Are You sure you want to delete the item...!!
              </h3>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <div className="">
                <button
                  className="bg-red-500 text-white mx-2 w-20 px-2 py-2 rounded-lg hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-violet-900"
                  onClick={() => handleDele()}
                >
                  Yes
                </button>
              </div>

              <div>
                <button
                  className="bg-red-700 w-20 text-white px-2 py-2 mx-2 rounded-lg hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-700"
                  onClick={closeDeleteModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal> */}

        <Modal
          isOpen={viewModal}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "50%",
              height: "70%",
              maxWidth: "600px",
              margin: "auto",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            },
          }}
        >
          <div className="flex justify-end">
            <button
              className="text-red-500 hover:text-red-400"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h1 className="text-3xl font-semibold text-red-500 mb-4 text-center underline">
            View Item
          </h1>
          <div className="w-3/4 mx-auto">
            <div className=" mb-2 w-50 h-50 flex justify-center items-center ">
              {photo instanceof File ? (
                <div className=" ">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="item_photo"
                    className="rounded-lg h-md w-md mx-auto "
                  />
                </div>
              ) : (
                <div className="text-center mt-4 h-md w-md">
                  <img
                    src={`http://localhost:5000/api/v1/item/get-photo/${id}`}
                    alt="item_photo"
                    className="rounded-lg mx-auto"
                  />
                </div>
              )}
            </div>

            <div class="sm:col-span-3 mb-4">
              <label
                for="cakeType"
                class="block text-red-500 text-lg font-bold leading-6"
              >
                Cake type
              </label>
              <input
                type="text"
                value={cakeType}
                readOnly
                className="block w-full p-2 mt-2 border-1 border-violet-400 font-bold text-red-500  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setCakeType(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="name"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Name
              </label>
              <input
                type="text"
                value={name}
                readOnly
                className="block w-full p-2 mt-2 border-1 border-violet-400 font-bold text-red-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="mb-4">
              <label
                for="flavour"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Flavour
              </label>
              <input
                type="text"
                value={flavour}
                readOnly
                className="block w-full mt-2 p-2 border-1 border-violet-400 font-bold text-red-500  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setFlavour(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="theme"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Theme
              </label>
              <input
                type="text"
                value={theme}
                readOnly
                className="block w-full mt-2 p-2 border-1 border-violet-400 font-bold text-red-500  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setTheme(e.target.value)}
              />
            </div>

            <div class="mb-4">
              <label
                for="flavour"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Shape
              </label>
              <input
                type="text"
                value={shape}
                readOnly
                className="block w-full mt-2 p-2 border-1 border-violet-400 font-bold text-red-500  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setShape(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="description"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Description
              </label>
              <input
                type="text"
                value={description}
                readOnly
                className="block mt-2 w-full p-2 border-1 border-violet-400 font-bold text-red-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="cakeId"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Cake Id
              </label>
              <input
                type="text"
                value={cakeId}
                readOnly
                className="block mt-2 w-full p-2 border-1 border-violet-400 text-red-500 font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setCakeId(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="color"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Color
              </label>
              <input
                type="text"
                value={color}
                readOnly
                className="block w-full mt-2 p-2 border-1 border-violet-400 text-red-500 font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setColor(e.target.value)}
              />
            </div>

            <div class="mb-4">
              <label
                for="weight"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Weight
              </label>
              <input
                type="text"
                value={weight}
                readOnly
                className="block w-full p-2 mt-2 border-1 border-violet-400 text-red-500 font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>

            <div class="mb-4">
              <label
                for="tier"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Tier
              </label>
              <input
                type="text"
                value={tier}
                readOnly
                className="block w-full p-2 mt-2 border-1 border-violet-400 text-red-500 font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setTier(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                for="price"
                className="block text-lg font-bold leading-6 text-red-500"
              >7
                Price
              </label>
              <input
                type="text"
                value={price}
                readOnly
                className="block w-full p-2 mt-2 border-1 border-violet-400 text-red-500 font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
        </Modal>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={() => setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="container  mx-auto mt-8 p-4">
                    <div
                      className="bg-white shadow-md rounded-lg p-8 mb-4 text-red-500"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <div>
                        <button
                          className="bg-red-500 "
                          onClick={handleCloseModal}
                          style={{
                            position: "absolute",
                            top: "80px",
                            right: "50px",
                            borderRadius: "50%",
                          }}
                        >
                          <svg
                            height="30px"
                            id="Layer_1"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="30px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                          </svg>
                        </button>
                      </div>

                      <h1 className="text-3xl font-semibold mb-6 mt-4 text-center">
                        Update Item
                      </h1>
                      <div className="w-3/4 mx-auto">
                        <div className="mb-6 w-50 h-50">
                          <label className="block w-full  border-dashed border-3 border-red-400 cursor-pointer text-center">
                            <AiOutlineCamera className="w-8 h-8 mx-auto mb-2" />
                            {photo ? photo.name : "Upload Photo"}
                            <input
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => setPhoto(e.target.files[0])}
                              hidden
                            />
                          </label>
                          {photo instanceof File ? (
                            <div className="text-center">
                              <img
                                src={URL.createObjectURL(photo)}
                                alt="item_photo"
                                className="rounded-lg h-md w-md mx-auto"
                              />
                            </div>
                          ) : (
                            <div className="text-center mt-4 h-md w-md">
                              <img
                                src={`http://localhost:5000/api/v1/item/get-photo/${id}`}
                                alt="item_photo"
                                className="rounded-lg mx-auto"
                              />
                            </div>
                          )}
                        </div>

                        <div class="sm:col-span-3 mb-4">
                          <label
                            for="cakeType"
                            class="block text-lg font-bold leading-6"
                          >
                            Cake type
                          </label>
                          <div class="mt-2">
                            <select
                              id="cakeType"
                              name="cakeType"
                              value={cakeType}
                              onChange={(e) => setCakeType(e.target.value)}
                              className="border-1 border-violet-400  focus:ring-2 focus:ring-pink-500 w-full rounded-lg py-1.5 "
                            >
                              <option disabled selected value="">
                                Select cake type
                              </option>
                              <option value="StandardCake">StandardCake</option>
                              <option value="Pastry">Pastry</option>
                              <option value="Cupcake">Cupcake</option>
                              <option value="Jarcake">Jarcake</option>
                              <option value="Drycakes">Drycakes</option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            for="name"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            value={name}
                            placeholder="Name"
                            className="block w-full p-2 mt-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div class="mb-4">
                          <label
                            for="flavour"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Flavour
                          </label>
                          <select
                            id="flavour"
                            name="flavour"
                            value={flavour}
                            onChange={(e) => setFlavour(e.target.value)}
                            className="border-1 border-violet-400 mt-2 focus:ring-2 focus:ring-pink-500 w-full rounded-lg py-1.5 "
                          >
                            <option disabled selected value="">
                              Select Flavour
                            </option>
                            <option value="Pineapple">Pineapple</option>
                            <option value="Vanilla">Vanilla</option>
                            <option value="Butterscotch">Butterscotch</option>
                            <option value="Chocolate">Chocolate</option>
                            <option value="Blackforest">Blackforest</option>
                            <option value="Whiteforest">Whiteforest</option>
                            <option value="Rasmalai">Rasmalai</option>
                            <option value="Blackcurrent">Blackcurrent</option>
                            <option value="Blueberry">Blueberry</option>
                            <option value="Strawberry">Strawberry</option>
                            <option value="Paan">Paan</option>
                            <option value="Redvelvet">Redvelvet</option>
                            <option value="Chocotruffle">Chocotruffle</option>
                          </select>
                        </div>

                        <div className="mb-6">
                          <label
                            for="theme"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Theme
                          </label>
                          <input
                            type="text"
                            value={theme}
                            className="block w-full mt-2 p-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setTheme(e.target.value)}
                          />
                        </div>

                        <div class="mb-4">
                          <label
                            for="flavour"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Shape
                          </label>
                          <select
                            id="shape"
                            name="shape"
                            value={shape}
                            onChange={(e) => setShape(e.target.value)}
                            className="border-1 border-violet-400 mt-2 focus:ring-2 focus:ring-pink-500 w-full rounded-lg py-1.5 "
                          >
                            <option disabled selected value="">
                              Select Shape
                            </option>
                            <option value="Rectangle">Rectangle</option>
                            <option value="Heart">Heart</option>
                            <option value="Square">Square</option>
                            <option value="Triangle">Triangle</option>
                            <option value="Polygon">Polygon</option>
                            <option value="Pentagon">Pentagon</option>
                            <option value="Circular">Circular</option>
                          </select>
                        </div>

                        <div className="mb-6">
                          <label
                            for="description"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Description
                          </label>
                          <input
                            type="text"
                            value={description}
                            className="block mt-2 w-full p-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="mb-6">
                          <label
                            for="cakeId"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Cake Id
                          </label>
                          <input
                            type="text"
                            value={cakeId}
                            className="block mt-2 w-full p-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setCakeId(e.target.value)}
                          />
                        </div>

                        <div className="mb-6">
                          <label
                            for="color"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Color
                          </label>
                          <input
                            type="text"
                            value={color}
                            className="block w-full mt-2 p-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setColor(e.target.value)}
                          />
                        </div>

              <div className="mt-2">
              <label
                htmlFor="weight"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Weight
              </label>
              <div className="flex mt-2 items-center">
                <input
                  id="weight"
                  name="weight"
                  type="text"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  className="w-1/2 mx-1 font-bold text-red-500 px-2 rounded-md border-0 py-1.5 text-red-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
                <select
                  id="weight"
                  name="weight"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  className="w-1/2 font-bold text-red-500 px-2 rounded-md border-0 py-1.5 text-red-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                   <option value="select unit" default>Select Unit</option>
                  <option value="gm">gm</option>
                  <option value="kg">kg</option>
                  <option value="piece">piece</option>
                </select>
              </div>
            </div>

                        <div class="mb-4">
                          <label
                            for="tier"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Tier
                          </label>
                          <select
                            id="tier"
                            name="tier"
                            value={tier}
                            onChange={(e) => setTier(e.target.value)}
                            className="border-1 border-violet-400 mt-2 focus:ring-2 focus:ring-pink-500 w-full rounded-lg py-1.5 "
                          >
                            <option disabled selected value="">
                              Select Tier
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>

                        <div className="mb-6">
                          <label
                            for="price"
                            className="block text-lg font-bold leading-6 text-red-500"
                          >
                            Price
                          </label>
                          <input
                            type="text"
                            value={price}
                            className="block w-full p-2 mt-2 border-1 border-violet-400  rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>

                        <div className="d-flex justify-content-center mb-4">
                          <button
                            className="bg-red-500 text-white px-2 py-2 rounded-lg hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-violet-900"
                            onClick={handleUpdate}
                          >
                            UPDATE ITEM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster />
      </div>
    </>
  );
};

export default ViewItems;

import React, { useState, useEffect } from "react";
import toast, { Toaster }  from "react-hot-toast";
import axios from "axios";

const CustomisedCake = () => {
  const [quantity, setQuantity] = useState("");
  const [flavour, setFlavour] = useState("");
  const [theme, setTheme] = useState("");
  const [shape, setShape] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("");
  const [tier, setTier] = useState("");
  const [price, setPrice] = useState("");
  const [occasionType, setOccasionType] = useState("");
  const [writeName, setWriteName] = useState("");
  const [addPhoto, setAddPhoto] = useState("");
  const [design, setDesign] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const tierPrice = tier ? parseInt(tier) * 100 : 0;
    const calculatedPrice = tierPrice * parseInt(quantity);
    setPrice(calculatedPrice);
  }, [quantity, tier]);

  function loadRazorpay() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setLoading(true);
        const result = await axios.post("/api/v1/payment/create-customCakepayment", {
          amount: price * 100,
          paymentMode: true,
          price: price * 100,
        });

        const { amount, id: order_id, currency } = result.data;
        const razorpayKeyResponse = await axios.get("/api/v1/payment/get-razorpay-key");
        const { key: razorpayKey } = razorpayKeyResponse.data;
        const options = {
          key: razorpayKey,
          amount,
          currency,
          name: "manasvi technologies",
          description: "transaction to manasvi",
          order_id,
          handler: async (response) => {
            const paymentData = {
              paymentMode: true,
              amount,
              razorpay: {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
              buyer:userData._id,
              quantity,
                flavour,
                theme,
                shape,
                description,
                color,
                tier,
                deliveryDate,
                deliveryTime,
                occasionType,
                writeName,
                addPhoto,
                design,
                price,
              itemData: {
                quantity,
                flavour,
                theme,
                shape,
                description,
                color,
                tier,
                deliveryDate,
                deliveryTime,
                occasionType,
                writeName,
                addPhoto,
                design,
              },
            };

            await axios.post("/api/v1/payment/pay-customcakepayment", paymentData);
            toast.success("Payment Completed Successfully ");
          },
          prefill: {
            name: "Manasvi technologies",
            email: "staff.manasvi@gmail.com",
            contact: "1111111111",
          },
          notes: {
            address: "30, minal residency bhopal D.",
          },
          theme: {
            color: "#80c0f0",
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const itemData = new FormData();
      itemData.append("quantity", quantity);
      itemData.append("flavour", flavour);
      itemData.append("theme", theme);
      itemData.append("shape", shape);
      itemData.append("description", description);
      itemData.append("color", color);
      itemData.append("tier", tier);
      itemData.append("price", price);
      itemData.append("deliveryDate", deliveryDate);
      itemData.append("deliveryTime", deliveryTime);
      itemData.append("occasionType", occasionType);
      itemData.append("writeName", writeName);
      itemData.append("addPhoto", addPhoto);
      itemData.append("design", design);

      const { data } = await axios.post("/api/v1/item/create-customitem", itemData);

      if (!data.success) {
        toast.error(data.message);
      }

      if (data.success) {
        setQuantity("");
        setFlavour("");
        setTheme("");
        setShape("");
        setDescription("");
        setColor("");
        setTier("");
        setDeliveryDate("");
        setDeliveryTime("");
        setPrice("");
        setOccasionType("");
        setWriteName("");
        setAddPhoto("");
        setDesign("");

        toast.success(data.message);
        setTimeout(() => {
          loadRazorpay();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-red-100">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <h1 className="text-3xl font-bold mb-4 underline text-red-500 text-center">
          Customised Cake
        </h1>
        <form className="space-y-4 flex">
          <div className={`w-full md:w-1/2 lg:w-1/2 mx-auto text-red-500`}>
            <div className="mb-3 font-bold text-red-500">
              <label
                for="quantity"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Quantity
              </label>
              <input
                type="number"
                value={quantity}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>

            <div class="sm:col-span-3 mb-4 text-red-500">
              <label
                for="flavour"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Flavour
              </label>
              <div class="mt-2">
                <select
                  id="flavour"
                  name="flavour"
                  value={flavour}
                  onChange={(e) => setFlavour(e.target.value)}
                  class="block w-full text-red-400 rounded-md font-bold border-0 py-1.5 text-red-500 shadow-sm ring-1 ring-inset ring-red-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option disabled selected value="">
                    Select Flavour
                  </option>
                  <option value="Pineapple">Pineapple</option>
                  <option value="Vanilla">Vanilla</option>
                  <option value="Butterscotch">Butterscotch</option>
                  <option value="Chocolate">Chocolate</option>
                  <option value="Blackforest">Blackforest</option>
                  <option value="Whiteforest">Whiteforest</option>
                  <option value="Rasmalai">Rasmalai</option>
                  <option value="Blackcurrent">Blackcurrent</option>
                  <option value="Blueberry">Blueberry</option>
                  <option value="Strawberry">Strawberry</option>
                  <option value="Paan">Paan</option>
                  <option value="Redvelvet">Redvelvet</option>
                  <option value="Chocotruffle">Chocotruffle</option>
                </select>
              </div>
            </div>

            <div className="mb-3 font-bold">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Theme
              </label>

              <input
                type="text"
                value={theme}
                className="block mt-2 w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setTheme(e.target.value)}
              />
            </div>

            <div class="mt-2">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Shape
              </label>
              <select
                id="shape"
                name="shape"
                value={shape}
                onChange={(e) => setShape(e.target.value)}
                className="block mt-2 w-full text-red-500 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-bold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option disabled selected value="">
                  Select Shape
                </option>
                <option value="Rectangle">Rectangle</option>
                <option value="Heart">Heart</option>
                <option value="Square">Square</option>
                <option value="Triangle">Triangle</option>
                <option value="Polygon">Polygon</option>
                <option value="Pentagon">Pentagon</option>
                <option value="Circular">Circular</option>
              </select>
            </div>

            <div className="mb-3 mt-4">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Description
              </label>
              <input
                type="field"
                value={description}
                className="block mt-2 font-bold w-full text-red-400 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Color
              </label>
              <input
                type="text"
                value={color}
                className="block w-full font-bold text-red-400 mt-2 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setColor(e.target.value)}
              />
            </div>

            <div class="mt-3">
              <label
                for="tier"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Tier
              </label>
              <select
                id="tier"
                name="tier"
                value={tier}
                onChange={(e) => setTier(e.target.value)}
                class="block w-full font-bold text-red-500 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option disabled selected value="">
                  Select Tier
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            <div className="mb-3 mt-3">
              <label
                for="price"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Price
              </label>
              <input
                type="number"
                value={price}
                className="block w-full font-bold text-red-400 mt-2 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            
            <div className="mb-3 font-bold text-red-500">
              <label
                for="occasionType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Occasion Type
              </label>
              <input
                type="text"
                value={occasionType}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setOccasionType(e.target.value)}
              />
            </div>

            <div className="mb-3 font-bold text-red-500">
              <label
                for="deliveryDate"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Delivery Date
              </label>
              <input
                type="date"
                value={deliveryDate}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </div>

            <div className="mb-3 font-bold text-red-500">
              <label
                for="deliveryTime"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Delivery Time
              </label>
              <input
                type="time"
                value={deliveryTime}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setDeliveryTime(e.target.value)}
              />
            </div>

            <div className="mb-3 font-bold text-red-500">
              <label
                for="writeName"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Name on cake :
              </label>
              <input
                type="text"
                value={writeName}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setWriteName(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label
                for="writeName"
                className="block text-lg font-bold leading-6 mb-3 mt-5 text-red-500"
              >
                Add Photo
              </label>
              <label className="block w-full p-4 border-dashed border-2 font-bold border-red-500 cursor-pointer text-center">
                {addPhoto ? addPhoto.name : "Upload Photo"}
                <input
                  type="file"
                  name="addPhoto"
                  accept="image/*"
                  // onChange={(e) => setPhoto(e.target.files[0])}
                  // hidden
                  onChange={(e) => {
                    setAddPhoto(e.target.files[0]);
                    e.target.value = null;
                  }}
                  hidden
                />
              </label>
            </div>

            <div className="mb-3 text-center ">
              {addPhoto && (
                <img
                  src={URL.createObjectURL(addPhoto)}
                  alt="Item_Photo"
                  className="block mx-auto max-h-40"
                />
              )}
            </div>

            <div className="mb-3">
              <label
                for="writeName"
                className="block text-lg font-bold leading-6 mb-3 mt-5 text-red-500"
              >
                Add Design Photo
              </label>
              <label className="block w-full p-4 border-dashed border-2 mb-3 font-bold border-red-500 cursor-pointer text-center">
                Add Design Photo
                {design ? design.name : "Upload Photo"}
                <input
                  type="file"
                  name="design"
                  accept="image/*"
                  // onChange={(e) => setPhoto(e.target.files[0])}
                  // hidden
                  onChange={(e) => {
                    setDesign(e.target.files[0]);
                    e.target.value = null;
                  }}
                  hidden
                />
              </label>
            </div>

            <div className="mb-3 text-center ">
              {design && (
                <img
                  src={URL.createObjectURL(design)}
                  alt="design_Photo"
                  className="block mx-auto max-h-40"
                />
              )}
            </div>

            <div className="mb-3 text-center mt-4">
              <button
                className=" w-full bg-red-500 hover:bg-pink-500 text-white mt-4 px-4 py-2 rounded"
                onClick={handleCreate}
                disabled={loading}
              >
                Checkout
              </button>
            </div>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default CustomisedCake;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Invoice from "./Invoice";

const CustomisedOrder = () => {
  const token = JSON.parse(localStorage.getItem("auth"));
  const userData = JSON.parse(localStorage.getItem("user"));
  const _id = userData._id;
  const [orderData, setOrderData] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const handleClick = (subscription) => {
    console.log(itemDetails, "itemmm");
    setSelectedOrder(subscription); // Set the selected order
    setIsInvoiceModalOpen(true);
  };

  const getOrders = async () => {
    try {
      const response = await axios.get(`/api/v1/payment/single-customorder/${_id}`);
      console.log("Response data:", response.data); // Log the response data for inspection
  
      const { success, message, order } = response.data;
  
      if (success && message === "find") {
        if (order && Array.isArray(order) && order.length > 0) { // Check if order is not empty and is an array
          console.log("Order data:", order); // Log the order data for inspection
          setOrderData(order);
  
          const itemDetailsPromises = order.map(orderItem => (
            orderItem.item && Array.isArray(orderItem.item) ? orderItem.item.map(item => (
              axios.get(`/api/v1/item/get-singleCustomItemById/${item.item}`)
            )) : []
          ));
  
          const itemDetailsResponses = await Promise.all(
            itemDetailsPromises.flat()
          );
  
          const itemDetailsData = itemDetailsResponses.map(
            (itemResponse) => itemResponse.data.item
          );
          setItemDetails(itemDetailsData);
        } else {
          console.log("No items found in order.");
        }
      } else {
        console.error("Error fetching orders:", message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };
  
  
  
  
  

  useEffect(() => {
    // Fetch orders only if token is present
    if (token) {
      getOrders();
      console.log(itemDetails, "item details");
    }
  }, [token, _id]);

  return (
    <div className="container mx-auto">
      <h1 className="text-center font-bold text-2xl mt-3 text-red-600 mb-4 underline">ORDERS</h1>
      {orderData.length === 0 && (
        <div className="mt-4">
          <h1 className="text-red-500 font-bold text-center">
            You don't have any orders
          </h1>
        </div>
      )}

      {orderData.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-red-500">
              <tr>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">Order ID</th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">Date</th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">Payment</th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">Payment Mode</th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">Invoice</th>
              </tr>
            </thead>
            <tbody className="bg-red-300">
              {orderData.map((order, orderIndex) => (
                <tr key={orderIndex}>
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {order?.razorpay?.orderId}
                  </td>
                  {/* <td className="py-3 px-4 text-left whitespace-pre-wrap">{order?.status}</td> */}
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {new Date(order?.createdAt).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {order?.isPaid ? "Success" : "Failed"}
                  </td>
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {order?.paymentMode ? "Online" : "Cash on delivery"}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <button
                      className="ml-2 text-blue-500 cursor-pointer"
                      onClick={() => handleClick(order)}
                    >
                      👁️
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Invoice
        isOpen={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
        orderData={selectedOrder}
      />
    </div>
  );
};

export default CustomisedOrder;

// Home.js
import React, { useState, useEffect } from "react";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Header from "./Header";
import Hmenu from "./Hmenu";
import axios from "axios";
import ScrollArrow from "./ScollArrow";
import CAKE from "../../assets/images/CAKE.png";
import CAKE1 from "../../assets/images/CAKE1.png";
import CAKE2 from "../../assets/images/CAKE2.png";
import homebg2 from "../../assets/images/homebg2.jpeg";
import bgims from "../../assets/images/bgims.avif";
import Menu from "../home/Menu";
import Footer from "./Footer";

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Homepage = () => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleItems, setVisibleItems] = useState(8);
  const itemsPerPage = 4;
  const showLoadMore = items.length > visibleItems;
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,

    onSubmit: async (values) => {
      try {
        const response = await axios.post("/api/contact", values);
        console.log(response);

        if (response.status === 201) {
          setSubmissionStatus("success");
          formik.resetForm();
        } else {
          setSubmissionStatus("error");
        }
      } catch (error) {
        setSubmissionStatus("error");
        console.error("Error:", error);
      }
      console.log(values);
    },
  });

  const images = [
    {
      url: "https://image3.jdomni.in/banner/09082022/E2/C2/1A/CD2B0DCE7903A5C3E9314BABE1_1660041250744.jpeg?output-format=webp",
    },
    {
      url: "https://image2.jdomni.in/banner/09082022/E6/9B/A3/6804900ED6FF50D5538802EE12_1660041289644.jpeg?output-format=webp",
    },
    {
      url: "https://image1.jdomni.in/banner/09082022/91/AD/1C/139C6B468886AA7DF6AE88E78E_1660041388354.png?output-format=webp",
    },
    {
      url: "https://image1.jdomni.in/banner/09082022/91/AD/1C/139C6B468886AA7DF6AE88E78E_1660041388354.png?output-format=webp",
    },
    {
      url: "https://image3.jdomni.in/banner/09082022/E2/C2/1A/CD2B0DCE7903A5C3E9314BABE1_1660041250744.jpeg?output-format=webp",
    },
    // Add more image objects here
  ];

  const CuisineItem = [
    {
      name: " Gujarati Cuisine",
      description:
        "Gujarati thali consists of rotli, dal or kadhi, rice, and shaak/sabzi with different combinations of vegetables and spices.",
      imageSrc:
        "https://image3.jdomni.in/banner/09082022/61/93/CE/DAFF5B7441C59762701C5E5665_1660038710602.png?output-format=webp",
    },
    {
      name: "Maharashtrian Cuisine",
      description:
        "Maharashtrian food offers something for everyone, it consists of dal, rice, roti, bhaji, puri and sweets.",
      imageSrc:
        "https://image3.jdomni.in/banner/09082022/71/BA/69/C048BB2C5874DEBBD147634B5D_1660038315016.png?output-format=webp",
    },
    {
      name: "Jain Cuisine",
      description:
        "Jain thali consists of vegetables, squash, beans, peas, fruits, and lettuce, and excludes onions and garlic, root vegetables.",
      imageSrc:
        "https://image2.jdomni.in/banner/09082022/86/39/87/817E0165BCAF84D7995BAC0E1F_1660037740713.png?output-format=webp",
    },
    {
      name: "South Indian Cuisine",
      description:
        "South Indian cuisine is simply delicious and easily digestible. It consists of dosa, idli, saaru/rasam, and huli/sambar.",
      imageSrc:
        "https://image3.jdomni.in/banner/09082022/68/44/31/3A491AA749FE6D38CC8B47EAD0_1660038119474.png?output-format=webp",
    },

    // Add more menu items here
  ];
  const menuItems = [
    {
      name: "  Lunch Tiffin Service",
      description:
        "Allow us to take care of your daily lunch, regardless if you’re at work or at home.",
      imageSrc:
        "https://image3.jdomni.in/banner/09082022/D3/C6/EC/C04026A8352CF83621769A27E3_1660040820356.jpeg?output-format=webp",
    },
    {
      name: "Nutri-meal Lunch",
      description:
        "What would you choose? Stale cooked hotel food or goodness and wholeness of nutritious food?",
      imageSrc:
        "https://image3.jdomni.in/banner/09082022/EC/AF/B4/6CA28C9484E3E692FE0393141C_1660040864035.jpeg?output-format=webp",
    },
    {
      name: "Customised Tiffin",
      description:
        "Yes, you can virtually design your own meals and choose from plenty of options provided by us.",
      imageSrc:
        "https://image2.jdomni.in/banner/09082022/C8/7F/23/A8CD036DF1915908AC4D3991A9_1660040995720.jpeg?output-format=webp",
    },
    {
      name: "Customised Tiffin",
      description:
        "Yes, you can virtually design your own meals and choose from plenty of options provided by us.",
      imageSrc:
        "https://image1.jdomni.in/banner/09082022/6B/12/88/7E7BE9E7C0FF29F59392C578D1_1660041168479.jpeg?output-format=webp",
    },
  ];

  const getAllItems = async () => {
    try {
      const { data } = await axios.get("/api/v1/item/get-item");
      if (data?.success) {
        const items = data.item.map((item) => ({
          ...item,
        }));
        setItems(items);
      }
    } catch (error) {
      console.error(error); // Use console.error for error messages
      // toast.error("Something went wrong in getting food");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllItems();
  }, []);

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to a server)
    console.log("Form data submitted:", formData);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000, // Slide transition duration in milliseconds
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
  };

  return (
    <>
      <Header />
      <div>
        <div className="relative h-auto w-full">
          {/* sl;ider */}
          <div style={{ height: "50%" }}>
            <Slider
              {...settings}
              className="rounded-xl mt-[5.5rem] mb-0 mr-5 ml-5"
            >
              <div>
                <img src={CAKE} alt="rep" className=" w-auto object-cover" />
              </div>
              <div>
                <img src={CAKE1} alt="res" className=" w-auto object-cover" />
              </div>
              <div>
                <img src={CAKE2} alt="tif" className=" w-auto object-cover" />
              </div>

              <div>
                <img src={CAKE} alt="sal" className=" w-auto object-cover" />
              </div>
              <div>
                <img src={CAKE2} alt="tra" className=" w-auto object-cover" />
              </div>
            </Slider>
          </div>
          <div className=" flex flex-1 absolute top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2 text-white text-center w-full">
            <div className="container mx-auto pl-8">
              <p className="text-gray-800 md:text-3xl tracking-normal mt-0 bg-transparent font-normal">
                {/* <span className="block md:text-6xl">Home Cooked and</span> */}
                {/* <span className="block italic md:text-6xl">
                  Fresh Cake Services
                </span> */}
              </p>
            </div>
          </div>
        </div>

        <header
          className=" py-4"
          style={{
            background:
              "linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))",
          }}
        >
          <div className="container mx-auto text-center decoration-black">
            <h1 className="md:text-4xl sm:text-2xl font-semibold">
              Welcome to Manvika Cake Service
            </h1>
            <p className="mt-2 sm:text-lg">
              Delicious and Healthy Cake Delivered to Your Doorstep
            </p>
          </div>
        </header>
      </div>
      <div>
        <div>
          <img style={{ width: "100%" }} src={homebg2} alt="IMg" />
        </div>
        <div>
          <Hmenu />
        </div>
      </div>
      <br />
      <Footer />
      <ScrollArrow />
    </>
  );
};
export default Homepage;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

Modal.setAppElement('#root');

const AttendanceModal = ({ isOpen, onClose, orderId }) => {
    const [mainDelivered, setMainDelivered] = useState(false);
    const [mainNotDelivered, setMainNotDelivered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalDelivered, setModalDelivered] = useState(false);
    const [modalNotDelivered, setModalNotDelivered] = useState(false);
    const [modalData, setModalData] = useState(null);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const date = getCurrentDate(); // Set the initial date

    useEffect(() => {
        // Fetch the attendance data when the component mounts
        fetchAttendanceData(orderId);
    }, [orderId]);

    const fetchAttendanceData = async (orderId) => {
        try {

            const response = await axios.get(`/attendence/Attendance/${orderId}`);
            setModalData(response.data);


            if (response.data) {
                setModalDelivered(response.data.delivered);
                setModalNotDelivered(response.data.notDelivered);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCheckboxChange = async () => {
        try {
            console.log('Checkbox clicked');

            const existingAttendanceResponse = await axios.get(`/attendence/Attendance/${orderId}?date=${date}`);

            if (existingAttendanceResponse.data.length > 0) {

                toast.error('Deliverey Status for this date and order already exists.');
            } else {

                await createAttendance(mainDelivered, mainNotDelivered, orderId);
                console.log('Delivery Status created successfully!');

                setMainDelivered(false);
                setMainNotDelivered(false);
            }
        } catch (error) {
            console.error('Error creating/updating Deliverey Status:', error);
        }
    };


    const createAttendance = async (delivered, notDelivered, orderId) => {
        try {
            const payload = {
                date,
                orderId,
                delivered: delivered ? 'Delivered' : 'n/a',
                notDelivered: notDelivered ? 'Not Delivered' : 'n/a',
            };

            const response = await axios.post(`/attendence/attendance/${orderId}`, payload);

            console.log('Delivery Status created successfully!', response.data);
            toast.success('Delivery Status created successfully!');

            // Update state based on the actual response7
            setMainDelivered(response.data.delivered);
            setMainNotDelivered(response.data.notDelivered);
        } catch (error) {
            toast.error('Error in creating Delivery Status ');
            console.error('Error in creating Delivery Status', error);
        }
    };


    const openModal = async () => {
        try {
            // Fetch data using the date as a parameter
            await fetchAttendanceData(orderId);

            // Open the modal
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleModalCheckboxChange = (isDelivered) => {
        if (isDelivered) {
            setModalDelivered((prev) => !prev);
        } else {
            setModalNotDelivered((prev) => !prev);
        }
    };

    const handleUpdate = async () => {
        try {
            // Update attendance data using the date as a parameter
            const payload = {
                orderId,
                date,
                delivered: modalDelivered ? 'Delivered' : 'n/a',
                notDelivered: modalNotDelivered ? 'Not Delivered' : 'n/a',
            };

            const response = await axios.put(`/attendence/Attendance/${orderId}`, payload);

            console.log('Delivery Status updated successfully!', response.data);
            toast.success('Attendance updated successfully!');

            // Close the modal after updating
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error updating  Delivery Status:', error);
            toast.error('Error updating Delivery Status:', error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Attendance Modal"
                style={{
                    content: {
                        width: '35%',
                        height: '60%',
                        maxWidth: '400px',
                        margin: 'auto',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    },
                }}
            >
                <div className="flex justify-end">
                    <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col items-center mt-4">
                    <h1 className="text-3xl font-bold mb-4 ">Delivery Status</h1>
                    <p className="text-1xl font-bold mb-4">Date: {date}</p>
                    <div className="mb-4">
                        <label className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={mainDelivered}
                                onChange={() => setMainDelivered(!mainDelivered)}
                                className="form-checkbox h-5 w-5 text-blue-500 mr-2"
                            />
                            <span>Delivered</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={mainNotDelivered}
                                onChange={() => setMainNotDelivered(!mainNotDelivered)}
                                className="form-checkbox h-5 w-5 text-blue-500 mr-2"
                            />
                            <span>Not Delivered</span>
                        </label>
                    </div>
                    <div className="flex justify-center space-x-4">
                        <button
                            onClick={handleCheckboxChange}
                            className="bg-blue-500 text-white py-2 px-4 rounded"
                        >
                            Save
                        </button>
                        <button onClick={openModal} className="bg-green-500 text-white py-2 px-4 rounded">
                            Edit
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Modal"
                style={{
                    content: {
                        width: '35%',
                        height: '60%',
                        maxWidth: '400px',
                        margin: 'auto',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    },
                }}
            >
                <div className="flex justify-end">
                    <button className="text-gray-500 hover:text-gray-700" onClick={closeModal}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col items-center mt-4">
                    <h1 className="text-3xl font-bold mb-4">Edit Delivery Status</h1>
                    <p className="text-1xl font-bold ">Date: {date}</p>
                    <br />
                    <label className="flex items-center ">
                        <input
                            type="checkbox"
                            checked={modalDelivered}
                            onChange={() => handleModalCheckboxChange(true)}
                            className="form-checkbox h-5 w-5 text-blue-500 mr-2"
                        />
                        <spap> Delivered</spap>
                    </label>
                    <br />
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={modalNotDelivered}
                            onChange={() => handleModalCheckboxChange(false)}
                            className="form-checkbox h-5 w-5 text-blue-500 mr-2"
                        />
                        <span>Not Delivered</span>
                    </label>
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            className="bg-green-500 text-white py-2 px-4 rounded"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </Modal>
            <Toaster />
        </>
    );
};

export default AttendanceModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { RiEditFill } from "react-icons/ri";
import { MdDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";

const ViewAllDel = () => {
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedDk, setSelectedDk] = useState(null);
  const isMobileView = window.innerWidth <= 420;
  const [searchId, setSearchId] = useState("");

  const EditModal = ({ selectedStaff, closeEditModal }) => {
    const [formData, setFormData] = useState({
      name: selectedStaff.name,

      status: selectedStaff.status,
      pincode: selectedStaff.pincode,
      address: selectedStaff.address,
      phone: selectedStaff.phone,
      dln: selectedStaff.dln,
      acn: selectedStaff.acn,
      uniqueId: selectedStaff.uniqueId,
      email: selectedStaff.email,
      upiId: selectedStaff.upiId,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleStatusChange = (e) => {
      const status = e.target.value;
      setFormData({
        ...formData,
        status,
      });
    };

    const handleEdit = async () => {
      try {
        await axios.put(
          `/api/v1/auth/update-del/${selectedStaff._id}`,
          formData
        );

        toast.success("Delboy Updated successfully");
        closeEditModal();

        getAllCustomer();
      } catch (error) {
        console.error(error);
        toast.error("Error in Updating  staff");
      }
    };

    return (
      <div className="fixed inset-0 flex items-center md:w-1/0.5 p-4 mx-auto text-center justify-center  bg-opacity-75">
        <div className="bg-white p-8 rounded-lg max-w-full max-h-full overflow-auto">
        <div className="flex justify-end ">
            <button
              className="text-red-500 hover:text-red-400"
              onClick={closeEditModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h2 className="text-2xl font-semibold underline text-red-500">
            Update Delivery Person
          </h2>
          <form className="flex flex-col items-center p-4">
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="name" className="w-1/4">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="phone" className="w-1/4">
                Contact:
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="dln" className="w-1/4">
                Driving Lc. No:
              </label>
              <input
                type="text"
                id="dln"
                name="dln"
                value={formData.dln}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="acn" className="w-1/4">
                Aadhar:
              </label>
              <input
                type="text"
                id="acn"
                name="acn"
                value={formData.acn}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="uniqueId" className="w-1/4">
                uniqueId:
              </label>
              <input
                type="text"
                id="uniqueId"
                name="uniqueId"
                value={formData.uniqueId}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="status" className="w-1/4">
                Status:
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleStatusChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="address" className="w-1/4">
                Address:
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="pincode" className="w-1/4">
                Pin Code:
              </label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="email" className="w-1/4">
                Email:
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="email" className="w-1/4">
                Upi Id:
              </label>
              <input
                type="text"
                id="upiId"
                name="upiId"
                value={formData.upiId}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <button
                className="mt-2 bg-red-500 text-white px-4 py-2 mx-2 rounded hover:bg-pink-500"
                onClick={() => handleEdit(selectedStaff._id, formData)}
              >
                Update
              </button>
          </form>

        </div>
      </div>
    );
  };
  const ViewModal = ({ selectedDk, closeViewModal }) => {
    console.log(selectedDk, "all prop");
    const [formData, setFormData] = useState({
      name: selectedDk.name,
      upiId: selectedDk.upiId,
      status: selectedDk.status,
      pincode: selectedDk.pincode,
      address: selectedDk.address,
      phone: selectedDk.phone,
      dln: selectedDk.dln,
      acn: selectedDk.acn,
      uniqueId: selectedDk.uniqueId,
      email: selectedDk.email,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleStatusChange = (e) => {
      const status = e.target.value;
      setFormData({
        ...formData,
        status,
      });
    };

    return (
      <div className="fixed inset-0 flex items-center md:w-1/0.5 p-4 mx-auto text-center justify-center  bg-opacity-75">
        <div className="bg-white p-8 rounded-lg max-w-full max-h-full overflow-auto">
          <div className="flex justify-end ">
            <button
              className="text-red-500 hover:text-red-400"
              onClick={closeViewModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h2 className="text-2xl font-semibold underline text-red-500">
            View Delivery
          </h2>

          <form className="flex flex-col items-center p-4">
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="name" className="w-1/4">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                readOnly
                value={formData.name}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="phone" className="w-1/4">
                Contact:
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                readOnly
                value={formData.phone}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="dln" className="w-1/4">
                Driving Lc. No:
              </label>
              <input
                type="text"
                id="dln"
                name="dln"
                readOnly
                value={formData.dln}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="acn" className="w-1/4">
                Aadhar:
              </label>
              <input
                type="text"
                id="acn"
                name="acn"
                readOnly
                value={formData.acn}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="uniqueId" className="w-1/4">
                uniqueId:
              </label>
              <input
                type="text"
                id="uniqueId"
                name="uniqueId"
                value={formData.uniqueId}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="status" className="w-1/4">
                Status:
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleStatusChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="address" className="w-1/4">
                Address:
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="pincode" className="w-1/4">
                Pin Code:
              </label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="email" className="w-1/4">
                Email:
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4 w-full flex justify-between items-center">
              <label htmlFor="upiId" className="w-1/4">
                Upi Id:
              </label>
              <input
                type="text"
                id="upiId"
                name="upiIdo"
                value={formData.upiId}
                onChange={handleInputChange}
                className="w-3/4 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
          </form>
        </div>
      </div>
    );
  };
  const getAllCustomer = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/auth/get-searchDel/${id}`
        : "/api/v1/auth/get-delboy";
      const { data } = await axios.get(url);
      console.log(data, "dafafsdf");

      setStaff(data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };
  const handleSearch = () => {
    getAllCustomer(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    getAllCustomer();
  }, []);

  const openEditModal = (index) => {
    setSelectedStaff(staff[index]);
  };

  const closeEditModal = () => {
    setSelectedStaff(null);
  };

  // start here for view
  const openViewModal = (index) => {
    setSelectedDk(staff[index]);
  };

  const closeViewModal = () => {
    setSelectedDk(null);
  };

  //end her for view

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`/api/v1/auth/delete-del/${_id}`);
      toast.success("DelBoy Deleted Successfully....!");
      closeEditModal();
      getAllCustomer();
    } catch (error) {}
  };

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-semibold text-center mt-1 mb-6 text-red-600 underline">
        All Delivery Boy
      </h1>
      <div className="mb-4 flex justify-end text-red-500 font-bold">
        <input
          type="text"
          placeholder="search by id name invoice"
          className="p-2 mr-2 border"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          onKeyUp={handleEnterKey}
        />
        <button
          className="bg-red-500 hover:bg-pink-500 text-white px-4 py-2 rounded "
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse shadow-lg rounded-lg">
          <thead>
            <tr className="text-white bg-red-500">
              <th className="py-3 px-4 text-left">Sn</th>
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">UniqueId</th>
              <th className="py-3 px-4 text-left">Contact</th>
              <th className="py-3 px-4 text-left">Address</th>
              <th className="py-3 px-4 text-left">Date</th>
              <th className="py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(staff) &&
              staff?.map((p, index) => (
                <tr
                  style={{ border: "1px solid black" }}
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-red-300 " : "bg-red-300"
                  } bg-purple-300`}
                >
                  <td className="py-3 px-4 whitespace-nowrap">{index + 1}</td>
                  <td className="py-1">{p.name}</td>
                  <td className="py-1">{p.uniqueId}</td>
                  <td className="py-1">{p.phone}</td>
                  <td className="py-1">{p.address}</td>
                  <td
                    className={`px-0 py-2 ${
                      !isMobileView ? "flex flex-row items-center" : ""
                    }`}
                  >
                    {moment(p.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td className="px-3">
                    <button
                      className="hover:text-blue-500 mr-2 hover:text-2xl"
                      onClick={() => openViewModal(index)}
                    >
                      <LuEye />
                    </button>
                    <button
                      className="hover:text-blue-500  mr-2 hover:text-2xl"
                      onClick={() => openEditModal(index)}
                    >
                      <RiEditFill />
                    </button>
                    <button
                      className="hover:text-blue-500 mr-2 hover:text-2xl"
                      onClick={() => handleDelete(p._id)}
                    >
                      <MdDeleteSweep />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {selectedStaff && (
        <EditModal
          selectedStaff={selectedStaff}
          closeEditModal={closeEditModal}
        />
      )}

      {selectedDk && (
        <ViewModal selectedDk={selectedDk} closeViewModal={closeViewModal} />
      )}
    </div>
  );
};

export default ViewAllDel;

import React, { useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import zxcvbn from "zxcvbn";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const CreateCakeSeller = () => {
  const [cakeId, setCakeId] = useState("");
  const [cakeOwnerName, setCakeOwnerName] = useState("");
  const [cakeName, setCakeName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincode1, setPincode1] = useState("");
  const [pincode2, setPincode2] = useState("");
  const [pincode3, setPincode3] = useState("");
  const [pincode4, setPincode4] = useState("");
  const [status, setStatus] = useState("");
  const [upiId, setUpiId] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/add-cake", {
        cakeOwnerName,
        cakeName,
        email,
        cakeId,
        password,
        phone,
        address,
        pincode,
        pincode1,
        pincode2,
        pincode3,
        pincode4,
        status,
        subscriptionPlan,
        startDate,
        endDate,
        upiId
      });

      if (res && res.data.success) {
        setCakeId("");
        setCakeOwnerName("");
        setCakeName("");
        setEmail("");
        setPassword("");
        setPhone("");
        setAddress("");
        setPincode("");
        setPincode1("");
        setPincode2("");
        setPincode3("");
        setPincode4("");
        setStatus("");
        setSubscriptionPlan("");
        setStartDate("");
        setEndDate("");
        setUpiId("")

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const subscriptionPlanOptions = [
    "Monthly",
    "Quarterly",
    "Half Yearly",
    "Yearly",
  ];

  const allpincode = [
    "462001",
    "462002",
    "462003",
    "462004",
    "462005",
    "462006",
    "462007",
    "462008",
    "462009",
    "462010",
    "462011",
    "462012",
    "462013",
    "462014",
    "462015",
    "462016",
    "462017",
    "462018",
    "462019",
    "462020",
    "462021",
    "462022",
    "462023",
    "462024",
    "462025",
    "462026",
    "462027",
    "462028",
    "462029",
    "462030",
    "462031",
    "462032",
    "462033",
    "462034",
    "462035",
    "462036",
    "462037",
    "462038",
    "462039",
    "462040",
    "462041",
    "462042",
    "462043",
    "462044",
    "462045",
    "462046",
    "462047",
    "462048",
    "462049",
    "462050",
    "462051",
    "462052",
    "462053",
    "462054",
    "462055",
    "462056",
    "462057",
    "462058",
    "462059",
    "462060",
    "462061",
    "462062",
    "462063",
    "462064",
    "462065",
    "462066",
    "462067",
    "462068",
    "462069",
    "462070",
    "462071",
    "462072",
    "462073",
    "462074",
    "462075",
    "462076",
    "462077",
    "462078",
    "462079",
    "462080",
    "462081",
    "462082",
    "462083",
    "462084",
    "462085",
    "463106",
    "463111",
    "464993",
  ];

  function getPasswordStrengthText(score) {
    const strengthLevels = [
      "Very Weak",
      "Weak",
      "Moderate",
      "Strong",
      "Very Strong",
    ];
    return strengthLevels[score];
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center mt-1 mb-6 text-red-500 underline">
          Add Cake Seller
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="ownerName"
              className="block font-bold text-red-500"
            >
              Owner Name
            </label>
            <input
              id="tcOwnerName"
              name="tcOwnerName"
              value={cakeOwnerName}
              onChange={(e) => setCakeOwnerName(e.target.value)}
              type="text"
              autoComplete="name"
              required
              className="w-full py-2 px-3 font-bold text-red-400 border rounded-lg focus:outline-none focus:border-red-500"
            />
          </div>

          <div>
            <label
              htmlFor="tiffinName"
              className="block font-bold text-red-500"
            >
              Business Name
            </label>
            <input
              id="tiffinName"
              name="tiffinName"
              value={cakeName}
              onChange={(e) => setCakeName(e.target.value)}
              type="text"
              autoComplete="name"
              required
              className="w-full font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block font-bold text-red-500"
            >
              Email address
            </label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block font-bold text-red-500"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              >
                {showPassword ? (
                  <AiOutlineEye className="h-5 w-5 font-bold text-red-500" />
                ) : (
                  <AiOutlineEyeInvisible className="h-5 w-5 text-red-500" />
                )}
              </span>
            </div>
            <div className="text-sm font-bold text-red-500">
              Password Strength:{" "}
              {password && (
                <span className="text-red-500">
                  {getPasswordStrengthText(zxcvbn(password).score)}
                </span>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="number"
              className="block font-bold text-red-500"
            >
              Phone Number
            </label>
            <input
              id="number"
              name="number"
              type="tel"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="cakeId"
              className="block font-bold text-red-500"
            >
              Cake Seller ID
            </label>
            <input
              id="cakeId"
              name="cakeId"
              value={cakeId}
              onChange={(e) => setCakeId(e.target.value)}
              type="text"
              autoComplete="name"
              required
              className="w-full py-2 px-3 font-bold text-red-400 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="address"
              className="block font-bold text-red-500"
            >
              Address
            </label>
            <input
              id="address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              autoComplete="address"
              required
              className="w-full py-2 px-3 font-bold text-red-400 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>

          <div>
            <label
              htmlFor="pincode"
              className="block font-bold text-red-500"
            >
              PinCode
            </label>
            <select
              id="pincode"
              name="pincode"
              type="number"
              autoComplete="pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              required
              className="w-full font-bold font-bold text-red-400 text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              {" "}
              <option value="" disabled hidden>
                Select Pincode
              </option>
              {allpincode.map((option) => (
                <option
                  key={option}
                  value={option}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="pincode1"
              className="block font-bold text-red-500"
            >
              PinCode 1
            </label>
            <select
              id="pincode1"
              name="pincode1"
              type="number"
              autoComplete="pincode1"
              value={pincode1}
              onChange={(e) => setPincode1(e.target.value)}
              required
              className="w-full over:bg-red-500 font-bold text-red-400  font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              {" "}
              <option value="" disabled hidden>
                Select Pincode1
              </option>
              {allpincode.map((option1) => (
                <option
                  key={option1}
                  value={option1}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option1}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="pincode2"
              className="block font-bold text-red-500"
            >
              PinCode 2
            </label>
            <select
              id="pincode2"
              name="pincode2"
              type="number"
              autoComplete="pincode2"
              value={pincode2}
              onChange={(e) => setPincode2(e.target.value)}
              required
              className="w-full py-2 px-3 font-bold text-red-400  border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              {" "}
              <option value="" disabled hidden>
                Select Pincode2
              </option>
              {allpincode.map((option2) => (
                <option
                  key={option2}
                  value={option2}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option2}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="pincode3"
              className="block font-bold text-red-500"
            >
              PinCode 3
            </label>
            <select
              id="pincode3"
              name="pincode3"
              type="number"
              autoComplete="pincode3"
              value={pincode3}
              onChange={(e) => setPincode3(e.target.value)}
              required
              className="w-full py-2 px-3 font-bold text-red-400 border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              {" "}
              <option value="" disabled hidden>
                Select Pincode3
              </option>
              {allpincode.map((option3) => (
                <option
                  key={option3}
                  value={option3}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option3}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="pincode4"
              className="block font-bold text-red-500"
            >
              PinCode 4
            </label>
            <select
              id="pincode4"
              name="pincode4"
              type="number"
              autoComplete="pincode4"
              value={pincode4}
              onChange={(e) => setPincode4(e.target.value)}
              required
              className="w-full py-2 px-3 font-bold text-red-400  border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              <option value="" disabled hidden>
                Select Pincode4
              </option>
              {allpincode.map((option4) => (
                <option
                  key={option4}
                  value={option4}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option4}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="status"
              className="block font-bold text-red-500"
            >
              Status
            </label>
            <div className="flex items-center">
              <input
                type="radio"
                id="active"
                name="status"
                value="Active"
                checked={status === "Active"}
                onChange={() => setStatus("Active")}
                className="sr-only"
              />
              <label
                htmlFor="active"
                className={`cursor-pointer inline-block p-2 rounded-full mr-2 ${
                  status === "Active" ? "bg-green-400" : "bg-gray-200"
                }`}
              >
                Active
              </label>

              <input
                type="radio"
                id="inactive"
                name="status"
                value="Inactive"
                checked={status === "Inactive"}
                onChange={() => setStatus("Inactive")}
                className="sr-only"
              />
              <label
                htmlFor="inactive"
                className={`cursor-pointer inline-block p-2 rounded-full ${
                  status === "Inactive" ? "bg-red-500" : "bg-gray-200"
                }`}
              >
                Inactive
              </label>
            </div>
          </div>

          <div>
            <label
              htmlFor="subscriptionPlan"
              className="block font-bold text-red-500"
            >
              Subscription Plan:
            </label>
            <select
              id="subscriptionPlan"
              name="subscriptionPlan"
              value={subscriptionPlan}
              onChange={(e) => setSubscriptionPlan(e.target.value)}
              required
              className="w-full py-2 px-3 border font-bold text-red-400  rounded-lg focus:outline-none focus:border-indigo-500 p-2"
            >
              <option value="" disabled hidden>
                Select Plan
              </option>
              {subscriptionPlanOptions.map((option) => (
                <option
                  key={option}
                  value={option}
                  className="hover:bg-red-500 font-bold text-red-400 hover:text-white"
                >
                  {option}
                </option>
              ))}
            </select>
          </div>
          

          <div>
            <label
              htmlFor="startDate"
              className="block font-bold text-red-500"
            >
              Upi Id
            </label>
            <input
              id="upiId"
              name="upiId"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
              onClick={(e) => e.target.focus()}
              type="text"
              autoComplete="upiId"
              required
              className="w-full py-2 px-3 font-bold text-red-400  border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
              
              
          <div>
            <label
              htmlFor="startDate"
              className="block font-bold text-red-500"
            >
              Start Date
            </label>
            <input
              id="startDate"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              onClick={(e) => e.target.focus()}
              type="date"
              autoComplete="startDate"
              required
              className="w-full py-2 px-3 border font-bold text-red-400 rounded-lg focus:outline-none focus:border-pink-500"
            />
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block font-bold text-red-500"
            >
              End Date
            </label>
            <input
              id="endDate"
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              onClick={(e) => e.target.focus()}
              type="date"
              autoComplete="endDate"
              required
              className="w-full py-2 px-3 border font-bold text-red-400 rounded-lg focus:outline-none focus:border-pink-500"
            />
          </div>

          <div className="mt-4 d-flex justify-content-center">
            <button
              type="submit"
              className="font-bold bg-red-500
            text-gray-100 rounded-full ring-2 ring-blue-200 px-6 py-2 
            hover:bg-pink-500 hover:text-black-400 
            flex items-center justify-center"
              onClick={handleSubmit}
            >
              Create Cake Seller
            </button>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateCakeSeller;

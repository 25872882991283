import React, { useState, useEffect } from "react";
import axios from "axios";
import AttedenceModal from "./AttendenceModal";

const CustomerList = () => {
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [customerDetailsMap, setCustomerDetailsMap] = useState({});
  const [tcDetailsMap, setTcDetailsMap] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const _id = userData._id;
  useEffect(() => {
    // Fetch all delivery orders
    axios
      .get(`/api/v1/tfc/finddelbyid/${_id}`)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setDeliveryOrders(data.data);

          // Fetch customer details for each order
          data.data.forEach((order) => {
            fetchCustomerDetails(order.orderId);
            fetchTcDetails(order.orderId);
          });
        } else {
          console.error("Failed to retrieve delivery orders");
        }
      })
      .catch((error) => {
        console.error("Error fetching delivery orders", error);
      });
  }, []);

  const handleClick = (orderId) => {
    // Set the selected order and open the attendance modal
    setSelectedOrder(orderId);
    setIsAttendanceModalOpen(true);
  };

  const fetchCustomerDetails = async (orderId) => {
    try {
      // Fetch customer details based on orderId
      const response = await axios.get(`/api/v1/tfc/${orderId}`);
      const data = response.data;
      console.log(data);

      if (data.success) {
        // Update customerDetailsMap with customer details for the specific order
        setCustomerDetailsMap((prevMap) => ({
          ...prevMap,
          [orderId]: data.data,
        }));
      } else {
        console.error("Failed to retrieve customer details");
      }
    } catch (error) {
      console.error("Error fetching customer details", error);
    }
  };

  const fetchTcDetails = async (orderId) => {
    try {
      // Fetch customer details based on orderId
      const response = await axios.get(`/api/v1/tfc/gettc/${orderId}`);
      const data = response.data;

      if (data.success) {
        // Update tc details with customer details for the specific order
        setTcDetailsMap((prevMap) => ({
          ...prevMap,
          [orderId]: data.data,
        }));
      } else {
        console.error("Failed to retrieve tc details");
      }
    } catch (error) {
      console.error("Error fetching tc details", error);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-1">
      <h1 className="text-4xl font-semibold text-center mb-2 underline text-red-600">
        Customer List
      </h1>

      {deliveryOrders && deliveryOrders.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse bg-white shadow-lg rounded-lg mt-4">
            <thead>
              <tr className="bg-gradient-to-r from-red-500 to-red-500 text-white">
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  S no
                </th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Date
                </th>
   
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Customer Name
                </th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Customer ID
                </th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Address
                </th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Contact
                </th>
                <th className="py-3 px-4 text-left whitespace-pre-wrap">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-red-300">
              {deliveryOrders.map((order, index) => (
                <tr key={order._id}>
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {index + 1}
                  </td>
                  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                    {order.createdAt}
                  </td>
                  {tcDetailsMap[order.orderId] && (
                    <>
                     {/*  <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {tcDetailsMap[order.orderId].cakeName}
                      </td>
                      <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {tcDetailsMap[order.orderId].cakeId}
                      </td> */}
                    </>
                  )}
                  {customerDetailsMap[order.orderId] && (
                    <>
                      <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {customerDetailsMap[order.orderId].customerName}
                      </td>
                      <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {customerDetailsMap[order.orderId].customerId}
                      </td>
                      <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {customerDetailsMap[order.orderId].address}
                      </td>
                      <td className="py-3 px-4 text-left whitespace-pre-wrap">
                        {customerDetailsMap[order.orderId].contact}
                      </td>
                    </>
                  )}
                  <td
                    className="py-3 px-4 text-left cursor-pointer text-blue-700 hover:underline"
                    onClick={() => handleClick(order.orderId)}
                  >
                    Add here
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Attendence Modal */}
      <AttedenceModal
        isOpen={isAttendanceModalOpen}
        onClose={() => setIsAttendanceModalOpen(false)}
        orderId={selectedOrder}
      />
    </div>
  );
};

export default CustomerList;

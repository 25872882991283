import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const PaymentReceipt = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    const userdData = JSON.parse(localStorage.getItem("user"));
    console.log(userdData.cakeId, "cakeId Id fForm loacalstorage");
    const cakeId = userdData.cakeId;

    if (cakeId) {
      fetch(`/api/v1/payment/get-single-Subs/${cakeId}`)
        .then((response) => response.json())
        .then((result) => {
          console.log("API Result:", result.data);
          setData(result.data);
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      console.error("cakeId not found in localStorage");
    }
  }, []);

  const generatePdf = (selectedSubscription) => {
    // event.preventDefault();

    console.log("Generating PDF...");
    if (!selectedSubscription) {
      console.error("data is not available for generating pdf");
      return;
    }
    try {
      const doc = new jsPDF();
      // Header
      doc.setFontSize(20);
      doc.text("Receipt", 105, 20, { align: "center" });

      // Company Name
      doc.setFontSize(16);
      doc.text("Manvika Consultancy Services Private Limited", 105, 40, {
        align: "center",
      });
      doc.text("“You Choose, We Fulfill”", 105, 60, { align: "center" });

      // Content
      const marginLeft = 60;
      const dataNameX = marginLeft;
      const valueX = 140;

      doc.setFontSize(12);

      // Date
      doc.text("Date:", dataNameX, 80, { align: "center" });
      doc.text(
        new Date(selectedSubscription.createdAt).toLocaleDateString(),
        valueX,
        80,
        { align: "center" }
      );

      // Receipt No
      doc.text("Receipt No:", dataNameX, 90, { align: "center" });
      doc.text(selectedSubscription.invoiceNo.toString(), valueX, 90, {
        align: "center",
      });

      // Received From
      doc.text("Received From:", dataNameX, 100, { align: "center" });
      doc.text(selectedSubscription.cakeOwnerName.toString(), valueX, 100, {
        align: "center",
      });

      // Rs
      doc.text("Rs:", dataNameX, 110, { align: "center" });
      doc.text(selectedSubscription.amount.toString(), valueX, 110, {
        align: "center",
      });

      // For
      doc.text("For:", dataNameX, 120, { align: "center" });
      doc.text(selectedSubscription.subscriptionPlan.toString(), valueX, 120, {
        align: "center",
      });

      doc.text("Signature", dataNameX, 140, { align: "left" });

      // Save the PDF
      doc.save("Receipt.pdf");
      console.log("PDF generated successfully");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <form className="w-full max-w-6xl border border-gray-300 p-6 rounded-md">
          <h1 className="underline text-red-600 text-center p-0 m-0 text-3xl my-0">
            Receipt
          </h1>

          <h1 className="text-red-500 text-center p-2 m-2 text-2xl">
            Manvika Consultancy Services Private Limited
          </h1>
          <h1 className="text-red-500 text-center p-2 m-2 text-2xl">
            “You Choose, We Fulfill”
          </h1>
          <br />

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                htmlFor="invoice-date"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Date:
              </label>
              <input
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="invoice-date"
                value={new Date(data?.createdAt).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                type="text"
                placeholder="Enter date"
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                htmlFor="invoice-number"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Receipt No:
              </label>
              <input
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="invoice-number"
                type="text"
                value={data?.invoiceNo}
                placeholder=""
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                htmlFor="bill-to"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Received From:
              </label>
              <input
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bill-to"
                type="text"
                value={data?.cakeOwnerName}
                placeholder=""
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                htmlFor="address"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Rs:
              </label>
              <input
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="address"
                type="text"
                value={data?.amount}
                placeholder=""
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                htmlFor="contact"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                for:
              </label>
              <input
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="contact"
                type="text"
                value={data?.subscriptionPlan}
                placeholder=""
              />
            </div>
          </div>
          <div className="text-center mt-8">
            <button
              className="bg-red-500 hover:bg-pink-500 text-white mt-4 font-bold py-2 px-4 rounded"
              onClick={() => generatePdf(data)}
            >
              Download Invoice
            </button>
          </div>
          <br />
          <br />
          <div className="flex justify-end items-center">
            <div className="text-right">
              <p className="w-32 md:w-32 lg:w-48 text-xl font-medium text-gray-700">
                Signature
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PaymentReceipt;

import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateItem = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [flavour, setFlavour] = useState("");
  const [theme, setTheme] = useState("");
  const [shape, setShape] = useState("");
  const [description, setDescription] = useState("");
  const [cakeId, setCakeId] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [tier, setTier] = useState("");
  const [price, setPrice] = useState("");
  const [cakeType, setCakeType] = useState("");
  const [photo, setPhoto] = useState("");
  const [unit, setUnit] = useState("");

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const itemData = new FormData();
      itemData.append("name", name);
      itemData.append("flavour", flavour);
      itemData.append("theme", theme);
      itemData.append("shape", shape);
      itemData.append("description", description);
      itemData.append("cakeId", cakeId);
      itemData.append("color", color);
      itemData.append("weight", weight);
      itemData.append("tier", tier);
      itemData.append("price", price);
      itemData.append("unit", unit);
      itemData.append("cakeType", cakeType);
      itemData.append("photo", photo);
      console.log(itemData, "iteeem");
      const { data } = await axios.post("/api/v1/item/create-item", itemData);
      if (!data.success) {
        toast.error(data.message);
      }

      if (data.success) {
        setName("");
        setFlavour("");
        setTheme("");
        setShape("");
        setDescription("");
        setCakeId("");
        setColor("");
        setWeight("");
        setTier("");
        setPrice("");
        setUnit("");
        setCakeType("");
        setPhoto("");

        toast.success(data.message);

        navigate("/admindashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-red-100">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <h1 className="text-3xl font-bold mb-4 underline text-red-500 text-center">
          Create Items
        </h1>
        <form className="space-y-4 flex">
          <div className={`w-full md:w-1/2 lg:w-1/2 mx-auto text-red-500`}>
            <div className="sm:col-span-3 mb-4 mt-2">
              <label
                for="category"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Category
              </label>
              <div className="mt-2 font-bold text-md text-red-500">
                <input
                  id="category"
                  name="category"
                  type="text"
                  defaultValue="100% Eggless"
                  value={"100% Eggless"}
                  readOnly
                  className="block w-full rounded-md border-0 py-1.5 text-red-500 ring-1 ring-inset ring-pink-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6 text-red-500"
                />
              </div>
            </div>
            <div className="sm:col-span-3 mb-4 text-red-500">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Cake type
              </label>
              <div className="mt-2">
                <select
                  id="cakeType"
                  name="cakeType"
                  value={cakeType}
                  onChange={(e) => setCakeType(e.target.value)}
                  className="block w-full text-red-500 font-bold rounded-md border-0 py-1.5 text--red-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option disabled selected value="">
                    Select cake type
                  </option>
                  <option value="Standard Cake">Standard Cake</option>
                  <option value="Pastry">Pastry</option>
                  <option value="Cup Cake">Cup Cake</option>
                  <option value="Jar Cake">Jar Cake</option>
                  <option value="Dry Cakes">Dry Cake</option>\
                  <option value="Fountain Cake">Fountain Cake</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label className="block w-full p-4 border-dashed border-2 font-bold border-red-500 cursor-pointer text-center">
                {photo ? photo.name : "Upload Photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  // onChange={(e) => setPhoto(e.target.files[0])}
                  // hidden
                  onChange={(e) => {
                    setPhoto(e.target.files[0]);
                    e.target.value = null;
                  }}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3 text-center ">
              {photo && (
                <img
                  src={URL.createObjectURL(photo)}
                  alt="Item_Photo"
                  className="block mx-auto max-h-40"
                />
              )}
            </div>
            <div className="mb-3 font-bold text-red-500">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Name
              </label>
              <input
                type="text"
                value={name}
                className="block mt-2 w-full font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="sm:col-span-3 mb-4 text-red-500">
              <label
                for="flavour"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Flavour
              </label>
              <div class="mt-2">
                <select
                  id="flavour"
                  name="flavour"
                  value={flavour}
                  onChange={(e) => setFlavour(e.target.value)}
                  class="block w-full text-red-500 rounded-md font-bold border-0 py-1.5 text-red-500 ring-1 ring-inset ring-red-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option disabled selected value="">
                    Select Flavour
                  </option>
                  <option value="Pineapple">Pineapple</option>
                  <option value="Vanilla">Vanilla</option>
                  <option value="Butterscotch">Butterscotch</option>
                  <option value="Chocolate">Chocolate</option>
                  <option value="Blackforest">Blackforest</option>
                  <option value="Whiteforest">Whiteforest</option>
                  <option value="Rasmalai">Rasmalai</option>
                  <option value="Blackcurrent">Blackcurrent</option>
                  <option value="Blueberry">Blueberry</option>
                  <option value="Strawberry">Strawberry</option>
                  <option value="Paan">Paan</option>
                  <option value="Redvelvet">Redvelvet</option>
                  <option value="Chocotruffle">Chocotruffle</option>
                </select>
              </div>
            </div>
            <div className="mb-3 font-bold">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Theme
              </label>

              <input
                type="text"
                value={theme}
                className="block mt-2 w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setTheme(e.target.value)}
              />
            </div>
            <div class="mt-2">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Shape
              </label>
              <select
                id="shape"
                name="shape"
                value={shape}
                onChange={(e) => setShape(e.target.value)}
                className="block mt-2 w-full text-red-500 rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 font-bold focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option disabled selected value="">
                  Select Shape
                </option>
                <option value="Rectangle">Rectangle</option>
                <option value="Heart">Heart</option>
                <option value="Square">Square</option>
                <option value="Triangle">Triangle</option>
                <option value="Polygon">Polygon</option>
                <option value="Pentagon">Pentagon</option>
                <option value="Circular">Circular</option>
              </select>
            </div>
            <div className="mb-3 mt-4">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Description
              </label>
              <input
                type="field"
                value={description}
                className="block mt-2 font-bold w-full text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="mb-3 ">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Cake Id
              </label>
              <input
                type="text"
                value={cakeId}
                className="block w-full mt-2 font-bold text-red-500 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setCakeId(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label
                for="thaliType"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Color
              </label>
              <input
                type="text"
                value={color}
                className="block w-full font-bold text-red-500 mt-2 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label
                htmlFor="weight"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Weight
              </label>
              <div className="flex mt-2 items-center">
                <input
                  id="weight"
                  name="weight"
                  type="text"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  className="w-1/2 mx-1 font-bold text-red-500 px-2 rounded-md border-0 py-1.5 text-red-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
                <select
                  id="weight"
                  name="weight"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  className="w-1/2 font-bold text-red-500 px-2 rounded-md border-0 py-1.5 text-red-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                   <option value="select unit" default>Select Unit</option>
                  <option value="gm">gm</option>
                  <option value="kg">kg</option>
                  <option value="piece">piece</option>
                </select>
              </div>
            </div>
          
            <div className="mt-3">
              <label
                for="tier"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Tier
              </label>
              <select
                id="tier"
                name="tier"
                value={tier}
                onChange={(e) => setTier(e.target.value)}
                class="block w-full font-bold text-red-500 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option disabled selected value="">
                  Select Tier
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="mb-3 mt-3">
              <label
                for="price"
                className="block text-lg font-bold leading-6 text-red-500"
              >
                Price
              </label>
              <input
                type="number"
                value={price}
                className="block w-full font-bold text-red-500 mt-2 px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="mb-3 text-center ">
              <button
                className=" w-full bg-red-500 hover:bg-pink-500 text-white px-4 py-2 rounded"
                onClick={handleCreate}
              >
                CREATE ITEM
              </button>
            </div>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateItem;

import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { RiEditFill } from "react-icons/ri";
import { MdDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";
import ViewCsModel from "./ViewCsModel";
import EditCs from "./EditCs";

const ViewCakeSellers = () => {
  const isMobileView = window.innerWidth <= 420;
  const [cs, setCs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [searchId, setSearchId] = useState("");
  const handleUpdate = async (formData) => {
    const { _id } = formData;
    try {
      const response = await axios.put(
        `/api/v1/auth/update-cs/${_id}`,
        formData
      );

      console.log("Response from server:", response);

      if (response.status === 201) {
        toast.success("Cake Seller Updated Successfully");
        console.log("CAke seller updated successfully");
        setEditedData(response.data.updatedUser);
        setShowEditModal(true);
        getAllCakeSellers();
      } else {
        console.error("Failed to update Cake Sellers");
      }
    } catch (error) {
      console.error("Error updating Cake Sellers:", error);
    }
  };

  const handleViewData = async (id) => {
    try {
      const response = await axios.get(`/api/v1/auth/get-cs/${id}`);

      if (response.status === 200) {
        // console.log("Tc View successfully");
        // Assuming response.data contains the individual item data
        setViewData(response.data);
        setShowModal(true);
      } else {
        console.error("Failed to View Cake Sellers");
      }
    } catch (error) {
      console.error("Error in view Cake Sellers:", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/auth/delete-cs/${id}`);
      const updatedCSData = cs.filter((item) => item._id !== id);
      setCs(updatedCSData);
    } catch (error) {
      console.error("Error deleting Cake Sellers data:", error);
    }
  };

  // get all customer
  const getAllCakeSellers = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/auth/getS-search/${id}`
        : "/api/v1/auth/get-allcakeseller";

      const res = await axios.get(url);
      const { data } = res;
      if (data) {
        if (data.length > 0) setCs(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };
  const handleSearch = () => {
    getAllCakeSellers(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // lifecycle method
  useEffect(() => {
    getAllCakeSellers();
  }, []);

  return (
    <div className="container mx-auto ">
      <h1 className="text-3xl font-semibold text-center mb-6 text-red-600 underline">
        All Cake Sellers
      </h1>
      <div className="mb-4 flex justify-end text-red-500 font-bold">
        <input
          type="text"
          placeholder="search by id name invoice"
          className="p-2 mr-2 border"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          onKeyUp={handleEnterKey}
        />
        <button
          className="bg-red-500  hover:bg-pink-500 text-white px-4 py-2 rounded"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white shadow-lg rounded-lg mt-4 border-grey-300">
          <thead>
            <tr className="bg-red-500 text-white">
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto text-left">
                Owner Name
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto text-left">
                Owner Id
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto text-left">
                Phone
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto text-left">
                Address
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto text-left">
                Serving Area
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto  text-left ">
                Status
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto  text-left ">
                Subscription Plan
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Subscription Expiry Date
              </th>
              <th className="py-3 px-4 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {cs?.map((p, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-red-000" : "bg-red-300"
                } bg-red-300`}
              >
                <td className="py-3 px-4 whitespace-nowrap w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                  {p.cakeName}
                </td>
                <td className="py-3 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p._id}
                </td>
                <td className="py-3 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p.phone}
                </td>
                <td className="py-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p.address}
                </td>
                <td className="py-3 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p.pincode}, {p.pincode1}, {p.pincode2}, {p.pincode3},{" "}
                  {p.pincode4}
                </td>
                <td className="py-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p.status}
                </td>
                <td className="py-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto px-4">
                  {p.subscriptionPlan}
                </td>
                <td
                  className={`px-3 py-5 mt-4  ${
                    !isMobileView ? "flex flex-row items-center" : ""
                  }`}
                >
                  {moment(p.endDate).format("DD-MM-YYYY")}
                </td>

                <td className="px-3 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                  <button
                    className="hover:text-blue-500 mr-2 "
                    onClick={() => handleViewData(p._id)}
                  >
                    <LuEye />
                  </button>
                  <button
                    className="hover:text-blue-500  mr-2"
                    onClick={() => handleUpdate(p)}
                  >
                    <RiEditFill />
                  </button>
                  <button
                    className="hover:text-blue-500 mr-2  "
                    onClick={() => handleDelete(p._id)}
                  >
                    <MdDeleteSweep />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <ViewCsModel
          onView={handleViewData}
          initialData={viewData}
          setOpenModal={setShowModal}
        />
      )}
      {showEditModal && (
        <EditCs
          onUpdate={handleUpdate}
          initialData={editedData}
          setOpenModal={setShowEditModal}
        />
      )}
    </div>
  );
};

export default ViewCakeSellers;

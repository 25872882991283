import React, { useState } from "react";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import zxcvbn from "zxcvbn";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const CreateDelivery = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [pincode, setpincode] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [dln, setDln] = useState("");
  const [acn, setAcn] = useState("");
  const [status, setStatus] = useState("");
  const [upiId, setUpiId] = useState("");
  /* const[qrCode, setQrCode] = useState(""); */

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("button clicked");
      const res = await axios.post("/api/v1/auth/add-delboy", {
        name,
        email,
        password,
        phone,
        address,
        pincode,
        uniqueId,
        dln,
        acn,
        status,
        upiId,
       /*  qrCode */
      });
      console.log(res);
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setName("");
        setAddress("");
        setpincode("");
        setEmail("");
        setPassword("");
        setPhone("");
        setAcn("");
        setDln("");
        setStatus("");
        setUniqueId("");
        setUpiId("");
        /* setQrCode(""); */
        console.log(res);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  function getPasswordStrengthText(score) {
    const strengthLevels = [
      "Very Weak",
      "Weak",
      "Moderate",
      "Strong",
      "Very Strong",
    ];
    return strengthLevels[score];
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 scroll-auto">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4 text-center text-center mt-1 mb-6 text-red-600 underline ">
          Add Delivery Boy
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block font-bold text-red-500"
            >
              Name
            </label>
            <input
              id="name"
              name="name"
              value={name}
              onChange={(e) => {
                const newName = e.target.value.slice(0, 50);
                setName(newName);
              }}
              type="text"
              autoComplete="name"
              required
              className="w-full py-2 px-3 border font-bold  text-red-400 rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block font-bold text-red-500"
            >
              Email address
            </label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              required
              className="w-full font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block font-bold text-red-500"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="w-full font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              >
                {showPassword ? (
                  <AiOutlineEye className="h-5 w-5 text-gray-500" />
                ) : (
                  <AiOutlineEyeInvisible className="h-5 w-5 text-gray-500" />
                )}
              </span>
            </div>
            <div className="text-sm text-gray-600">
              Password Strength:{" "}
              {password && (
                <span className="text-red-500">
                  {getPasswordStrengthText(zxcvbn(password).score)}
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="number"
              className="block font-bold text-red-500"
            >
              Phone Number
            </label>
            <input
              id="number"
              name="number"
              type="tel"
              autoComplete="tel"
              value={phone}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const validPhone = /^\d+$/.test(enteredValue);
                if (validPhone || enteredValue === "") {
                  setPhone(enteredValue);
                }
              }}
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block font-bold text-red-500"
            >
              Address
            </label>
            <input
              id="address"
              name="address"
              value={address}
              onChange={(e) => {
                const newAddress = e.target.value.slice(0, 100);
                setAddress(newAddress);
              }}
              type="text"
              autoComplete="address"
              required
              className="w-full font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="number"
              className="block font-bold text-red-500"
            >
              Enter Pincode
            </label>
            <input
              id="pincode"
              name="number"
              type="number"
              autoComplete="pincode"
              value={pincode}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const validpincode = /^\d+$/.test(enteredValue);
                if (validpincode || enteredValue === "") {
                  setpincode(enteredValue);
                }
              }}
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="text"
              className="block font-bold text-red-500"
            >
              Enter Unique Id
            </label>
            <input
              id="uniqueId"
              name="text"
              type="text"
              autoComplete="uniqueId"
              value={uniqueId}
              onChange={(e) => {
                const ev = e.target.value.slice(0, 100);
                setUniqueId(ev);
              }}
              required
              className="w-full py-2 px-3 font-bold text-red-400 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="acn"
              className="block font-bold text-red-500"
            >
              Aadhar Number
            </label>
            <input
              id="acn"
              name="acn"
              type="Number"
              autoComplete="acn"
              value={acn}
              onChange={(e) => {
                const ac = e.target.value.slice(0, 100);
                setAcn(ac);
              }}
              required
              className="w-full font-bold text-red-400 py-2 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="dln"
              className="block font-bold text-red-500"
            >
              Driving Licence Number
            </label>
            <input
              id="dln"
              name="dln"
              type="Number"
              autoComplete="dln"
              value={dln}
              onChange={(e) => {
                const dl = e.target.value.slice(0, 100);
                setDln(dl);
              }}
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="status"
              className="block font-bold text-red-500"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              value={status}
              onChange={(e) => {
                const ts = e.target.value;
                setStatus(ts);
              }}
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Inactive">Inactive</option>
              <option value="Active">Active</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="upiId"
              className="block font-bold text-red-500"
            >
              Upi Id
            </label>
            <input
              id="upiId"
              name="upiId"
              type="text"
              autoComplete="upiId"
              value={upiId}
              onChange={(e) => {
                setUpiId(e.target.value);
              }}
              required
              className="w-full py-2 font-bold text-red-400 px-3 border rounded-lg focus:outline-none focus:border-indigo-500"
            />
          </div>
       {/*    <div className="mb-3">
              <label className="block w-full p-4 border-dashed border-2 font-bold border-red-500 cursor-pointer text-center">
                {qrCode ? qrCode.name : "Upload Photo"}
                <input
                  type="file"
                  name="qrCode"
                  accept="image/*"
                  onChange={(e) => {
                    setQrCode(e.target.files[0]);
                    e.target.value = null;
                  }}
                  hidden
                />
              </label>
            </div> */}

        {/*     <div className="mb-3 text-center ">
              {qrCode && (
                <img
                  src={URL.createObjectURL(qrCode)}
                  alt="qrPic"
                  className="block mx-auto max-h-40"
                />
              )}
            </div> */}

          <div className="mt-4 text-center d-flex justify-content-center">
            <button
              type="submit"
              className="font-bold bg-red-500
            text-gray-100 rounded-full ring-2 ring-blue-200 px-6 py-2 
            hover:bg-pink-500 hover:text-white hover:ring-slate-300 
            flex items-center justify-center"
            >
              Create Delivery Boy
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateDelivery;

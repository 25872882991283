import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./components/home/Homepage";
import Login from "./components/home/Login";
import Registrater from "./components/home/Register";
import AdminDash from "./pages/admin/AdminDash";
import ForgetPassword from "./components/home/ForgetPassword";
import ResetPassword from "./components/home/ResetPassword";
import CustomerDash from "./pages/customer/CustomerDash";
import Menu from "./components/home/Menu";
import Cart from "./components/home/Cart";
import AdminProfile from "./pages/admin/AdminProfile";
import CustomerProfile from "./pages/customer/CustomerProfile";
import DelDashboard from "./pages/delBoy/DelDashboard";
import CakeDash from "./pages/cakemanager/CakeDash";
import CakeProfile from "./pages/cakemanager/CakeProfile";


function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/admindashboard" element={<AdminDash />}></Route>
          <Route path="/cakedashboard" element={<CakeDash />}></Route>
          <Route path="/customerdashboard" element={<CustomerDash />}></Route>
          <Route path="/delboydashboard" element={<DelDashboard/>}></Route>

          <Route path="/" element={<Homepage />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
          <Route path="/fogetpassword" element={<ForgetPassword />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Registrater />}></Route>

          <Route path="/menu" element={<Menu />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route
            path="/apua%b%c%d%e%f$q$w$e$r$t$y$u$d$h$a$rM$a"
            element={<AdminProfile />}
          ></Route>
          <Route
            path="/tpua%b%c%d%e%f$q$w$e$r$t$y$u$d$h$a$rM$a"
            element={<CakeProfile />}
          ></Route>
          <Route
            path="/cpua%b%c%d%e%f$q$w$e$r$t$y$u$d$h$a$rM$a"
            element={<CustomerProfile />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Router;

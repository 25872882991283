import React, { useState, useEffect } from "react";
import {toast, Toaster} from "react-hot-toast";

export default function EditCs({ setOpenModal, onUpdate, initialData }) {
  const [cs, setCs] = useState(initialData);
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState("");

  const subscriptionPlanOptions = [
    "Monthly",
    "Quarterly",
    "Halfyearly",
    "Yearly",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(initialData);
    setCs(initialData);
    setStatus(initialData.status);
  }, [initialData]);

  const handleUpdate = async (id) => {
    const updatedcs = {
      ...cs,
      status: status,
    };
    onUpdate(updatedcs);
    toast.success("Cake Seller Updated Sucessfully")
    setOpenModal(false);
  };                                                                                                                               

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="flex min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
          <div className="flex justify-end">
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
            <div className="mt-3 sm:flex">
              <div className="mt-2  sm:ml-4 sm:text-left w-full">
                <h4 className="text-2xl font-semibold text-gray-800 mb-4 text-center underline text-red-500">
                  Edit Cake Seller
                </h4>
                <div className="text-[15px]  text-gray-500">
                  <form>
                    <div className="mb-4">
                      <label className="block">Owner Name:</label>
                      <input
                        type="text"
                        name="cakeOwnerName"
                        value={cs.cakeOwnerName}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block"> Cake Name:</label>
                      <input
                        type="text"
                        name="cakeName"
                        value={cs.cakeName}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      ></input>
                    </div>
                    <div className="mb-4">
                      <label className="block">Cake Id:</label>
                      <input
                        type="text"
                        name="cakeId"
                        value={cs.cakeId}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Email:</label>
                      <input
                        type="text"
                        name="email"
                        value={cs.email}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Password:</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={cs.password}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Contact:</label>
                      <input
                        type="number"
                        name="phone"
                        value={cs.phone}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      ></input>
                    </div>
                    <div className="mb-4">
                      <label className="block">Address:</label>
                      <input
                        type="text"
                        name="address"
                        value={cs.address}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Status:</label>
                      <input
                        type="radio"
                        id="active"
                        name="status"
                        value="Active"
                        checked={status === "Active"}
                        onChange={() => setStatus("Active")}
                        className="sr-only"
                        required
                      />
                      <label
                        htmlFor="active"
                        className={`cursor-pointer inline-block p-2 rounded-full mr-2 ${
                          status === "Active" ? "bg-green-400" : "bg-gray-200"
                        }`}
                      >
                        Active
                      </label>

                      <input
                        type="radio"
                        id="inactive"
                        name="status"
                        value="Inactive"
                        checked={status === "Inactive"}
                        onChange={() => setStatus("Inactive")}
                        className="sr-only"
                        required
                      />
                      <label
                        htmlFor="inactive"
                        className={`cursor-pointer inline-block p-2 rounded-full ${
                          status === "Inactive" ? "bg-red-500" : "bg-gray-200"
                        }`}
                      >
                        Inactive
                      </label>
                    </div>
                    <div className="mb-4">
                      <label className="block">Pincode:</label>
                      <input
                        type="number"
                        name="pincode"
                        value={cs.pincode}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Pincode1:</label>
                      <input
                        type="number"
                        name="pincode1"
                        value={cs.pincode1}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Pincode2:</label>
                      <input
                        type="number"
                        name="pincode2"
                        value={cs.pincode2}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Pincode3:</label>
                      <input
                        type="number"
                        name="pincode3"
                        value={cs.pincode3}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Pincode4:</label>
                      <input
                        type="number"
                        name="pincode4"
                        value={cs.pincode4}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Subscription Plan:</label>
                      <select
                        type="text"
                        name="subscriptionPlan"
                        value={cs.subscriptionPlan}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      >
                        <option value="" disabled hidden>
                          Select Plan
                        </option>
                        {subscriptionPlanOptions.map((option) => (
                          <option
                            key={option}
                            value={option}
                            className="hover:bg-violet-500 hover:text-white"
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block">Upi Id:</label>
                      <input
                        type="text"
                        name="upiId"
                        value={cs.upiId}
                        onClick={(e) => e.target.focus()}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">Start Date:</label>
                      <input
                        type="date"
                        name="startDate"
                        value={cs.startDate}
                        onClick={(e) => e.target.focus()}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block">End Date:</label>
                      <input
                        type="date"
                        name="endDate"
                        value={cs.endDate}
                        onClick={(e) => e.target.focus()}
                        onChange={handleChange}
                        className="w-full border rounded-md p-2"
                        required
                      />
                    </div>
                  </form>
                </div>
                <div className="flex items-center gap-2 mt-3">
                  <button
                    className="flex-1 px-4 py-2 text-white bg-red-500 hover:bg-pink-500 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster/>
    </>
  );
}

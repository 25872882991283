import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AttendModal from "./AttendModal";
const AllCstomDeldOrders = () => {
  const [d, setData] = useState([]);
  console.log(d, "kaaldkadjaldkadlj");
  const [userData, setUserData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchId, setSearchId] = useState("");

  const getAllOrder = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/payment/get-searchOrder/${id}`
        : "/api/v1/payment/allorders";
      const res = await axios.get(url);
      console.log(res.data, "aggaya");
     /*  console.log(data, "data all order");
      console.log(Array.isArray(data)); */
      setData(res.data.orders || []); // Make sure to access the correct data structure
    } catch (error) {
      console.error("Error fetching orders:", error);
      // You can display an error toast or handle the error in another way
      toast.error("Error fetching orders. Please try again later.");
    }
  };

  const getUser = async (id, index) => {
    try {
      const { data } = await axios.get(`/api/v1/auth/get-sUser/${id}`);
      if (data) {
        setUserData((prevUserData) => {
          const updatedUserData = [...prevUserData];
          updatedUserData[index] = data.user;
          return updatedUserData;
        });
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      // You can display an error toast or handle the error in another way
      toast.error("Error fetching user. Please try again later.");
    }
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    // Fetch user data for each item's buyer
    if (Array.isArray(d)) {
      d.forEach((item, index) => {
        getUser(item.buyer, index);
      });
    }
  }, [d]);


  const paybtn = (order) => {
    setIsAttendanceModalOpen(true);
    setSelectedOrder(order);
  };

  const handleSearch = () => {
    getAllOrder(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSortAscending = () => {
    // Sort data in ascending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    setData(sortedData);
  };

  const handleSortDescending = () => {
    // Sort data in descending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setData(sortedData);
  };
  const handleFilterAndSort = () => {
    // Filter data based on the selected date range
    const filteredData = d.filter(
      (item) =>
        new Date(item.createdAt) >= new Date(startDate) &&
        new Date(item.createdAt) <= new Date(endDate)
    );

    // Sort filtered data in ascending order based on date
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    setData(sortedData);
  };
  return (
    <>
     
      <h1 className="text-3xl font-bold mb-4 text-center underline text-red-600">
        All Customised Orders Status
      </h1>
      <div>
        <div className="mb-4 flex flex-col md:flex-row ">
          <div className="mb-2 md:mb-0">
            <input
              type="text"
              placeholder="Search by order ID"
              className="p-2 border border-purple-900"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              onKeyUp={handleEnterKey}
            />
          </div>
          <div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded ml-2 hover:bg-pink-500"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        {/* for date start here  */}
        <div className="mt-4 flex flex-wrap items-center">
          <label htmlFor="str" className="font-bold text-red-500">
            Start Date
          </label>
          <input
            type="date"
            className="m-2 rounded-xl border-2 border-slate-500"
            value={startDate}
            onChange={handleStartDateChange}
          />

          <label htmlFor="edr" className="font-bold text-red-500">
            End Date
          </label>
          <input
            type="date"
            className="m-2 rounded-xl border-2 border-slate-500"
            value={endDate}
            onChange={handleEndDateChange}
          />

          <button
            onClick={handleFilterAndSort}
            className="bg-blue-500 text-white py-2 ml-3 mt-2 px-10 rounded hover:bg-pink-400"
          >
            Apply
          </button>
        </div>

        {/* Buttons for sorting */}
        <div className="mt-2 flex flex-wrap items-center">
          <button
            onClick={handleSortAscending}
            className="bg-green-500 text-white py-2 m-2 px-4 rounded hover:bg-pink-400"
          >
            Ascending
          </button>

          <button
            onClick={handleSortDescending}
            className="bg-red-500 text-white py-2 m-2 px-4 rounded hover:bg-pink-400"
          >
            Descending
          </button>
        </div>
        {/* for data end here */}
      </div>

      <div className="w-full overflow-x-auto mx-auto bg-white">
        <table className="min-w-full table-auto border-2 border-pink-500">
          <thead className="">
            <tr className="text-center bg-red-500 text-white">
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Date
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Customer Name
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Email
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Address
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Contact Number
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Pincode
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                OrderId
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Action
              </th>
            </tr>
          </thead>
          {d && Array.isArray(d) && d.map((item, index) => (
  <tr key={index} className="font-serif bg-red-300 text-black border-1">
    <td className="py-1 text-center">
      {new Date(item.createdAt).toLocaleDateString("en-GB")}
    </td>
    <td className="py-1 text-center">{userData[index]?.name}</td>
    <td className="py-1 text-center">{userData[index]?.email}</td>
    <td className="py-1 text-center">{userData[index]?.address}</td>
    <td className="py-1 text-center">{userData[index]?.phone}</td>
    <td className="py-1 text-center">{userData[index]?.pincode}</td>
    <td className="py-1 text-center">{item.razorpay.orderId}</td>
   
    <td className="py-1 text-center">
      <button
         onClick={() => paybtn(item._id)}
        className="hover:text-violet-800 font-bold underline text-pink-800 py-2"
      >
       View Delivery Status
      </button>
    </td>
  </tr>
))}

        </table>
      </div>
      <>
        <AttendModal
          isOpen={isAttendanceModalOpen}
          onClose={() => setIsAttendanceModalOpen(false)}
          orderId={selectedOrder}
        />
      </>
      <Toaster />
    </>
  );
};

export default AllCstomDeldOrders;

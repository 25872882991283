// CustomerOrder.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomerInvoice from "./CustomerInvoice";

const CustomerOrder = () => {
  const token = JSON.parse(localStorage.getItem("auth"));
  const userData = JSON.parse(localStorage.getItem("user"));
  const _id = userData._id;
  const [orderData, setOrderData] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const handleClick = (subscription) => {
    setSelectedOrder(subscription); // Set the selected order
    setIsInvoiceModalOpen(true);
  };

  const getOrders = async () => {
    try {
      const response = await axios.get(`/api/v1/payment/single-order/${_id}`);
      const { success, message, order } = response.data;

      if (success && message === "find") {
        setOrderData(order);

        const itemDetailsPromises = order.map((orderItem) =>
          orderItem.item.map((item) =>
            axios.get(`/api/v1/item/get-singleItemById/${item.item}`)
          )
        );
        const itemDetailsResponses = await Promise.all(
          itemDetailsPromises.flat()
        );
        const itemDetailsData = itemDetailsResponses.map(
          (itemResponse) => itemResponse.data.item
        );
        setItemDetails(itemDetailsData);
      } else {
        console.error("Error fetching orders:", message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  useEffect(() => {
    // Fetch orders only if token is present
    if (token) {
      getOrders();
    }
  }, [token, _id]);

  return (
    <div className="container mx-auto relative">
      <h1 className="text-center font-bold text-2xl mt-3 text-red-600 mb-4 underline">ORDERS</h1>
      {orderData.length === 0 && (
        <div className="mt-4">
          <h1 className="text-red-500 font-bold text-center">
            You don't have any orders
          </h1>
        </div>
      )}

      {orderData.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-red-500">
              <tr>
                <th className="px-6 py-3">Order ID</th>
                <th className="px-6 py-3">Status</th>
                <th className="px-6 py-3">Date</th>
                <th className="px-6 py-3">Payment</th>
                <th className="px-6 py-3">Payment Mode</th>
                <th className="px-6 py-3">Amount</th>
                <th className="px-6 py-3">Invoice</th>
              </tr>
            </thead>
            <tbody>
              {orderData.map((order, orderIndex) => (
                <tr key={orderIndex} className="bg-red-300 border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="px-6 py-3">
                    {order?.razorpay?.orderId}
                  </td>
                  <td className="px-6 py-3">{order?.status}</td>
                  <td className="px-6 py-3">
                    {new Date(order?.createdAt).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="px-6 py-3">
                    {order?.isPaid ? "Success" : "Failed"}
                  </td>
                  <td className="px-6 py-3">
                    {order?.paymentMode ? "Online" : "Cash on delivery"}
                  </td>
                  <td className="px-6 py-3">{order?.amount}</td>

                  <td className="px-2 py-2 whitespace-nowrap">
                    <button
                      className="ml-2 text-red-500 cursor-pointer"
                      onClick={() => handleClick(order)}
                     
                    >
                 👁️
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {itemDetails.length > 0 && (
        <div className="overflow-x-auto">
          <div className="card-container">
            <h2 className="text-lg font-semibold">Order Items</h2>
            <div className="flex flex-wrap -mx-4">
              {itemDetails.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 p-4"
                >
                  <div className="border p-4 rounded-md shadow-md">
                    <h3 className="text-lg font-semibold flex justify-center">
                      Name: {item.name}
                    </h3>
                    <div className="flex justify-center h-24 md:h-24 lg:h-40">
                      <img
                        src={`/api/v1/item/get-photo/${item._id}`}
                        alt={item.name}
                        className="mt-2 rounded-md"
                        width="30%"
                      />
                    </div>
                    <h3 className="text-lg font-semibold flex justify-center mt-2">
                      Description: {item.description}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <CustomerInvoice
        isOpen={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
        orderData={selectedOrder}
      />
    </div>
  );
};

export default CustomerOrder;